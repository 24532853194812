
import { useCallback, useMemo } from 'react';
import { PrivatePaths, PublicPaths } from 'routes';
import { isAuthenticated } from 'utils/Auth';
import { UserRoles } from 'utils/constants';
import { canTakeGeneralAssessment, canTakeIntermediateAssessment, canTakeIntermediateTrackGeneralAssessment } from 'utils/DFA/StudentTakeAssessmentUtils';
import { navigateToActualURL, navigateToGeneralAssessmentDFARoute } from 'utils/RouteUtils';
import { useAuthenticatedUser } from './useAuthenticatedUser';

export const useRoleNavigation = () => {
	const { userDetails } = useAuthenticatedUser();
	const hasAuthentication = isAuthenticated();

	const navLinkEnum = useMemo(() => ({
		[UserRoles.GLOBAL_ADMIN]: {
			navArray: [
				{ tabName: 'Dashboard', link: `${PrivatePaths.DFA_DASHBOARD}`, id: Math.random() },
				{ tabName: 'Institutions', link: `${PrivatePaths.INSTITUTIONS}`, id: Math.random() },
				{
					tabName: 'Courses',
					link: `${PrivatePaths.DFA_COURSES}`,
					id: Math.random(),
				},
				{ tabName: 'Library', link: PrivatePaths.LIBRARY, id: Math.random() },
				{ tabName: 'Announcement', link: PrivatePaths.ANNOUNCEMENT, id: Math.random() },
				{ tabName: 'Executive', link: PrivatePaths.EXECUTIVE, id: Math.random() },
			],
		},
		[UserRoles.SCHOOL_ADMIN]: {
			navArray: [
				// { tabName: 'Dashboard', link: `${PrivatePaths.DFA_DASHBOARD}`, id: Math.random() },
				// {
				//   tabName: 'School',
				//   link: `${PrivatePaths.DFA_INSTITUTIONS}/${institutionId}`,
				//   id: Math.random(),
				// },
				{
					tabName: 'Courses',
					link: `${PrivatePaths.DFA_COURSES}`,
					id: Math.random(),
				},
				{ tabName: 'Assessments', link: PrivatePaths.ASSESSMENTS, id: Math.random() },
				{ tabName: 'Live Session', link: PrivatePaths.LIVE_SESSION, id: Math.random() },

				{ tabName: 'Users', link: `${PrivatePaths.DFA_USERS}`, id: Math.random() },

				// { tabName: 'Library', link: PrivatePaths.LIBRARY, id: Math.random() },
				{ tabName: 'Activity Log', link: PrivatePaths.ACTIVITY_LOG, id: Math.random() },
				// { tabName: 'Announcement', link: PrivatePaths.ANNOUNCEMENT, id: Math.random() },
			],
		},
		[UserRoles.STUDENT]: {
			navArray: [
				{ tabName: 'Dashboard', link: `${PrivatePaths.DFA_DASHBOARD}`, id: Math.random() },
				{
					tabName: 'My Learning',
					link: `${PrivatePaths.MY_LEARNING}`,
					id: Math.random(),
				},
				{ tabName: 'Course', link: `${PrivatePaths.COURSES}`, id: Math.random() },
				{ tabName: 'Library', link: PrivatePaths.LIBRARY, id: Math.random() },
				{ tabName: 'Announcement', link: PrivatePaths.ANNOUNCEMENT, id: Math.random() },
				// { tabName: 'Live Session', link: PrivatePaths.LIVE_SESSION, id: Math.random() },
			],
		},
		[UserRoles.LECTURER]: {
			navArray: [
				// { tabName: 'Dashboard', link: `${PrivatePaths.DFA_DASHBOARD}`, id: Math.random() },
				{ tabName: 'Course', link: `${PrivatePaths.COURSES}`, id: Math.random() },
				// { tabName: 'Library', link: PrivatePaths.LIBRARY, id: Math.random() },

				// { tabName: 'Announcement', link: PrivatePaths.ANNOUNCEMENT, id: Math.random() },
				// { tabName: 'Live Session', link: PrivatePaths.LIVE_SESSION, id: Math.random() },
			],
		},
		[UserRoles.K12_TEACHER]: {
			navArray: [
				{ tabName: 'Home', link: `${PrivatePaths.DFA_DASHBOARD}`, id: Math.random() },
				{ tabName: 'Announcement', link: `${PrivatePaths.DFA_ANNOUNCEMENT}`, id: Math.random() },
				// { tabName: 'Live Session', link: `${PrivatePaths.DFA_LIVE_SESSION}`, id: Math.random() },
			],
		},
		[UserRoles.YOUTH]: {
			navArray: [
				{ tabName: 'Home', link: `${PrivatePaths.DFA_DASHBOARD}`, id: Math.random() },
				{ tabName: 'Announcement', link: `${PrivatePaths.DFA_ANNOUNCEMENT}`, id: Math.random() },
				// { tabName: 'Live Session', link: `${PrivatePaths.DFA_LIVE_SESSION}`, id: Math.random() },
			],
		},
		[UserRoles.CIVIL_SERVANT]: {
			navArray: [
				{ tabName: 'Home', link: `${PrivatePaths.DFA_DASHBOARD}`, id: Math.random() },
				{ tabName: 'Announcement', link: `${PrivatePaths.DFA_ANNOUNCEMENT}`, id: Math.random() },
				// { tabName: 'Live Session', link: `${PrivatePaths.DFA_LIVE_SESSION}`, id: Math.random() },
			],
		},
		DEFAULT: {
			navArray: [{ tabName: '', link: '', id: Math.random() }],
		},
	}), []);

	const handleRoleBasedNav = useCallback((role = 'DEFAULT') => {
		return navLinkEnum[UserRoles[role]]?.navArray;
	}, [navLinkEnum]);

	const handleRouteNavigation = useCallback((dfaUsers = null, userInfo = null) => {
		const dfaUser = !dfaUsers ? userDetails?.dfaUsers : dfaUsers
		const userDetail = !userInfo ? userDetails : userInfo

		if (!isAuthenticated()) {
			return navigateToActualURL(PublicPaths.LOGIN)
		}

		if (dfaUser) {

			const canTakeGeneralAssessmentOrIntermediate = canTakeGeneralAssessment(dfaUser, userDetail?.roles[0]) ||
				canTakeIntermediateTrackGeneralAssessment(dfaUser, userDetail?.roles[0])

			const canTakeIntermediateAssessmentOnly = canTakeIntermediateAssessment(dfaUser, userDetail?.roles[0])

			const isK12Student = userDetail?.roles[0] === UserRoles.K12_STUDENT

			if (isK12Student || canTakeIntermediateAssessmentOnly) {
				navigateToActualURL('/assessment')
				return
			}

			if (canTakeGeneralAssessmentOrIntermediate) {
				navigateToGeneralAssessmentDFARoute()
				return

			}
		}
		handleRoleBasedNav(userDetail?.selectedRole || userDetail?.roles[0])?.forEach((val, idx, arr) => {
			navigateToActualURL(arr[0]?.link)
		}
		);
	}, [handleRoleBasedNav, userDetails])

	return {
		handleRoleBasedNav,
		handleRouteNavigation,
	};
};
