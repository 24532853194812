import React, { useState } from 'react';
// import './index.css';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Header from './header';
import { Box, Button, Container, Grid, Step, StepLabel, Stepper } from '@material-ui/core';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import { ADD_USER } from 'graphql/mutations/DFA/users';
// import { navigateToActualURL } from 'utils/RouteUtils';
// import { PublicPaths } from 'routes';
import { QontoConnector, useStyles } from './styled.index';
import FinalStep from './FinalStep';
import { useNotification } from 'reusables/NotificationBanner';
import LoadingButton from 'reusables/LoadingButton';
import DFAFooter from 'reusables/DFAFooter';

const Register = () => {
  const [formData, setFormData] = useState({
    canRetakeTrackAssessmentAt: '',
    category: '',
    certification: '',
    computerAbility: '',
    course: '',
    createdBy: '',
    currentState: '',
    currentTrack: '',
    currentlyDoing: '',
    digitalParticipation: '',
    digitalSkills: '',
    digitalSkillsTrainingJourney: '',
    education: '',
    email: '',
    employment: '',
    extentOfDigitalSkills: '',
    firstname: '',
    middlename: '',
    gender: '',
    age: '',
    genderIntentional: '',
    generalAssessmentId: '',
    generalAssessmentNumberOfRetries: '',
    hasAlbinism: false,
    hasInternet: false,
    hasLaptop: false,
    hasOthers: false,
    hasPassedTrackGeneralAssessment: false,
    hasPhone: false,
    hasStartedGeneralAssessment: false,
    hasStartedTrackGeneralAssessment: false,
    hasTime: false,
    isDisabled: false,
    isHearingImpaired: false,
    isMovementImpaired: false,
    isPassedGeneralAssessment: false,
    isPassedTrackAssessment: false,
    isVisionImpaired: false,
    lastname: '',
    learnerClass: '',
    learningTrack: null,
    maritalStatus: '',
    monthlyIncome: '',
    numberOfSubjects: '',
    phone: '',
    platform: '',
    reasonForDigitalSkills: '',
    residentialArea: '',
    retakeGeneralAssessmentAt: '',
    schoolCategory: '',
    schoolType: '',
    schoolName: '',
    score: '',
    stateOfOrigin: '',
    subjectCategory: '',
    subjectName: '',
    trackAssessmentNumberOfRetries: '',
    trackGeneralAssessmentId: '',
    trackList: '',
    username: '',
    socialMediaHandle: '',
    hasFollowedOnSocialMedia: false,
    accessToDigitalDevice: false,
    frequentUseOfDigitalDevice: false,
    // accessToInternet: false,
    internetCommunication: false,
    isLearnWithDesktop: null,
    isLearnWithLaptop: null,
    isLearnWithSmartphone: null,
    isLearnWithTablet: null,
    courseContentRelevance: '',
    contentIdeasCommunication: '',
    easyPlatformNavigation: '',
    maxSlaTimeForComplaints: '',
    additionReadingMaterials: '',
    primaryLearningTool: '',
    courseContentEngagementRate: '',
    videoCoursesLength: '',
    canUseDigitalDevice: '',
    accessToInternet: '',
    communicateOnInternet: '',
    accessToDeviceAtHome: '',
    mainChallenge: '',
    preferredLearningEnvironment: '',
    programSuccessMeasure: '',
  }); // This will hold the data from all steps

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { handleSubmit, errors, control, register, getValues, setValue, watch, trigger, reset } =
    useForm({
      defaultValues: formData,
    });
  const notification = useNotification();

  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);

  const handleNext = () => {
    trigger().then((res) => {
      if (res) {
        const data = getValues();

        setFormData((prevData) => ({ ...prevData, ...data })); // Update the state with the data
        reset(formData);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
      }
    });
  };

  const stringToBoolean = (str) => {
    return str === 'true';
  };

  const handleBack = () => {
    const data = getValues();
    setFormData((prevData) => ({ ...prevData, ...data }));
    reset(formData);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // convert the string value to an array
    const booleanValue = value === 'true' || value === 'false' ? stringToBoolean(value) : value;
    // if the user is disabled, set all the disability fields to false

    if (name === 'isDisabled' && booleanValue === false) {
      setValue('isHearingImpaired', false);
      setValue('isMovementImpaired', false);
      setValue('isVisionImpaired', false);
      setValue('hasAlbinism', false);
      setValue('hasOthers', false);
    }
    setValue(name, booleanValue);
    trigger(`${name}`);
  };

  function onError(error) {
    notification.error({
      message: error?.message,
    });
  }
  function onCompleted(response, key) {
    const { ok, errors } = response[key];
    const status = ok === false ? 'error' : 'success';
    if (status === 'success') {
      setIsRegistrationComplete(true);
    }
    const message = errors
      ? errors.messages ||
        (Array.isArray(errors) && errors.map((error) => error.messages).join('. '))
      : 'User was added successfully';
    // notification[status]({
    //   message,
    // });
    status === 'error' &&
      notification[status]({
        message,
      });
  }

  const [addUserMutation, { loading: isLoading }] = useMutation(ADD_USER, {
    onCompleted: (response) => {
      reset(formData);
      onCompleted(response, 'createDfaUser');
    },
    onError: (error) => {
      reset(formData);
    },
  });
  const handleFinish = (value) => {
    const data = getValues();

    try {
      const payload = formData;

      formData.category = data.category;
      formData.certification = data.certification;
      formData.genderIntentional = data.genderIntentional;
      formData.numberOfSubjects = data.numberOfSubjects;
      formData.schoolCategory = data.schoolCategory;
      formData.schoolName = data.schoolName;
      formData.schoolType = data.schoolType;
      formData.subjectCategory = data.subjectCategory;
      formData.subjectName = data.subjectName;

      for (let val in payload) {
        if (payload[val] === 'YES') {
          payload[val] = true;
        } else if (payload[val] === 'NO') {
          payload[val] = false;
        }
        if (!payload.digitalParticipation) {
          delete payload.learningTrack;
        }
        if (payload.category !== 'K12_TEACHER') {
          delete payload.schoolName;
          delete payload.schoolCategory;
          delete payload.subjectCategory;
          delete payload.schoolType;
          delete payload.genderIntentional;
          delete payload.numberOfSubjects;
          delete payload.subjectName;
          delete payload.certification;
        }
      }

      addUserMutation({
        variables: {
          ...payload,
        },
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
    // Set the registration completion state to true
  };
  const stages = [
    { component: StepOne, name: 'StepOne' },
    { component: StepTwo, name: 'StepTwo' },
    { component: StepThree, name: 'StepThree' },
    { component: StepFour, name: 'StepFour' },
    { component: StepFive, name: 'StepFive' },
  ];
  const convertStagesToSteps = (stages) => {
    return stages.map((stage) => stage.name);
  };

  const steps = convertStagesToSteps(stages);
  const StageComponent = stages[activeStep].component;

  const isLastStep = activeStep === steps.length - 1;

  const dynamicText = `
    Welcome to the Digital for All Challenge 2.0 application stage. Kindly register by
    completing the various fields of this form before submitting your application. Ensure to
    provide correct information as applicants will be disqualified if found to have
    submitted false information. All asterisked (*) fields are compulsory and must be
    provided. Click on Submit Form when you are done. By filling this form, you consent
    Tech4Dev to use your data for Monitoring, Evaluation, Research and Learning purposes.
  `;

  function QontoStepIcon(props) {
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active || completed,
        })}
      >
        {active || completed ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedIcon className={classes.inactiveIcon} />
        )}
      </div>
    );
  }

  return (
    <>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          {isRegistrationComplete ? (
            <FinalStep />
          ) : (
            <form onSubmit={handleSubmit(handleFinish)}>
              <Box>
                <Header props={dynamicText} />
              </Box>

              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid className={classes.stepper}>
                  <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Box width="100%">
                  <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <StageComponent
                      control={control}
                      errors={errors}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      handleNext={handleNext}
                      formData={formData}
                      handleBack={handleBack}
                      trigger={trigger}
                      // handleInputChange={handleInputChange}
                      handleChange={handleChange}
                      watch={watch}
                    />
                    <Box
                      marginTop="20px"
                      paddingBottom="30px"
                      display="flex"
                      justifyContent={activeStep === 0 ? 'flex-end' : 'space-between'}
                      padding={3}
                    >
                      {activeStep > 0 && (
                        <Button
                          onClick={handleBack}
                          variant="outlined"
                          className={classes.textButton}
                        >
                          Previous
                        </Button>
                      )}
                      {!isLastStep && (
                        <Button onClick={handleNext} variant="contained" className={classes.button}>
                          Next
                        </Button>
                      )}
                      {isLastStep && (
                        <LoadingButton
                          variant="contained"
                          className={classes.button}
                          type="submit"
                          isLoading={isLoading}
                        >
                          Finish
                        </LoadingButton>
                      )}
                    </Box>
                  </div>
                </Box>
              </Box>
            </form>
          )}
        </Paper>
      </Container>
      <DFAFooter />
    </>
  );
};

export default Register;
