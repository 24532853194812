import React from 'react';
import Routes from 'routes';

const App = () => {
  React.useEffect(() => {
    if (process.env.REACT_APP_PROJECT_NAME?.includes('dfa')) {
      document.title = 'Tech4dev Digital For All';
    }
  }, []);
  return <Routes />;
};

export default App;
