import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React from 'react';
import {
  extentOfDigitalSkillsOptions,
  booleanOptions,
  computerAbilityOptions,
  digitalSkillsTrainingJourneyOptions,
  learningTrackOptions,
} from './variables';
import { getFormError } from 'utils/formError';
import { colors, fontWeight } from '../../../Css';

const StepThree = (props) => {
  const classes = useStyles();
  const { control, watch, handleChange, register, errors } = props;
  const isDigitalParticipation = watch('digitalParticipation') === 'Yes' ? true : false;

  return (
    <Grid container>
      <Typography
        style={{
          fontSize: '16px',

          color: '#083A55',
          margin: '12px 0',
          fontWeight: fontWeight.bold,
        }}
      >
        The information you provide in this section will help us guide you on the appropriate
        program of learning that is best suited for you. 
      </Typography>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            To what extent does your current work/studies involve the use of digital skills?
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="extentOfDigitalSkills"
            control={control}
            rules={{ required: true }}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="extentOfDigitalSkills"
                name="extentOfDigitalSkills"
                onChange={handleChange}
              >
                {extentOfDigitalSkillsOptions.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    control={<GreenRadio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
          {getFormError('extentOfDigitalSkills', errors).hasError && (
            <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
              {getFormError('extentOfDigitalSkills', errors).message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            Rate your ability on how to use a computer<span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="computerAbility"
            control={control}
            rules={{ required: true }}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="computerAbility"
                name="computerAbility"
                onChange={handleChange}
              >
                {computerAbilityOptions.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    style={{
                      color: '#083A55',
                      marginBottom: '10px',
                    }}
                    control={<GreenRadio />}
                    label={option.label}
                    className={classes.align}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>{' '}
        {getFormError('computerAbility', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('computerAbility', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            Which of the following best describe your digital skills training journey in the past?
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="digitalSkillsTrainingJourney"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="digitalSkillsTrainingJourney"
                name="digitalSkillsTrainingJourney"
                onChange={handleChange}
              >
                {digitalSkillsTrainingJourneyOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    checked={value === option.value}
                    value={option.value}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    control={<GreenRadio />}
                    label={option.label}
                    className={classes.align}
                  />
                ))}
              </RadioGroup>
            )}
          />{' '}
          {getFormError('digitalSkillsTrainingJourney', errors).hasError && (
            <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
              {getFormError('digitalSkillsTrainingJourney', errors).message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormLabel
          component="legend"
          style={{
            color: '#083A55',
            margin: '12px 0',
            fontWeight: fontWeight.bold,
            marginRight: '10px',
          }}
        >
          Did you participate in the Digital For All Challenge 1.0?
          <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <FormControl component="fieldset">
          <Controller
            name="digitalParticipation"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="digitalParticipation"
                name="digitalParticipation"
                onClick={handleChange}
                style={{ flexDirection: 'row' }}
              >
                {booleanOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    checked={value === option.value}
                    value={option.value}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    control={<GreenRadio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('digitalParticipation', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('digitalParticipation', errors).message}
          </p>
        )}
      </Grid>
      {isDigitalParticipation && (
        <Grid item xs={12} style={{ marginTop: '40px' }}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
            >
              What was the learning track for which you were trained?
              <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Controller
              name="learningTrack"
              rules={{ required: true }}
              control={control}
              render={({ field, value }) => (
                <RadioGroup
                  {...field}
                  aria-label="learningTrack"
                  name="learningTrack"
                  onChange={handleChange}
                >
                  {learningTrackOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      style={{
                        color: '#083A55',
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '100%',
                        marginBottom: '8px',
                      }}
                      checked={value === option.value}
                      value={option.value}
                      control={<GreenRadio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          {getFormError('learningTrack', errors).hasError && (
            <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
              {getFormError('learningTrack', errors).message}
            </p>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default StepThree;
const GreenRadio = withStyles({
  root: {
    color: colors.grey,
    '&$checked': {
      color: '#287D3C',
    },
    marginRight: 10,
    padding: 3,
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  align: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
}));
