import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { getFormError } from 'utils/formError';
import React from 'react';
import {
  booleanOptions,
  digitalSkillsOptions,
  platformOptions,
  frequencyOptions,
  accessTools,
  learningStyle,
  programSuccessMeasure,
  levelOfProgramQuality,
  challenges,
} from './variables';
import { colors, fontWeight } from '../../../Css';

const StepFour = (props) => {
  const { control, handleChange, register, errors, trigger } = props;

  const renderPreparationOptions = (name, label, handleChange, booleanArray, lg) => (
    <Grid
      container
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        spacing: 6,
      }}
    >
      <Grid
        item
        xs={7}
        sm={8}
        md={8}
        lg={lg || 8}
        style={{ marginRight: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}
      >
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
        >
          {label}
        </FormLabel>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} style={{ flexWrap: 'nowrap' }}>
        <FormControl component="fieldset">
          <Controller
            name={name}
            rules={{ required: { value: true, message: `Required field` } }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label={name}
                name={name}
                onChange={handleChange}
                style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
              >
                {booleanArray.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError(name, errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError(name, errors).message}
          </p>
        )}
      </Grid>
    </Grid>
  );

  const renderFrequencyOptions = (name, label, handleChange, options) => (
    <Grid container gap={4} my={6}>
      <Grid item xs={12}>
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
        >
          {label}
        </FormLabel>
      </Grid>
      <Grid item xs={12} md={12} style={{ flexWrap: 'nowrap', marginLeft: '4px', width: '100%' }}>
        <FormControl component="fieldset">
          <Controller
            name={name}
            rules={{ required: { value: true, message: `Required field` } }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label={name}
                name={name}
                onChange={handleChange}
                style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row' }}
              >
                {options?.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      justifySelf: 'flex-end',
                      // width: '100%',
                      // marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError(name, errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError(name, errors).message}
          </p>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Typography
        style={{
          fontSize: '16px',
          color: '#083A55',
          margin: '12px 0',
          fontWeight: fontWeight.bold,
        }}
      >
        The information you provide in this section will help us guide you on the appropriate
        program of learning that is best suited for you. 
      </Typography>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            If you are to learn a digital skill, which of these skills interest you the most?
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Typography style={{ fontSize: '12px', fontStyle: 'italic', marginBottom: '10px' }}>
            Please note that not all courses listed here are available on the Learning Management
            System (LMS)
          </Typography>
          <Controller
            name="digitalSkills"
            control={control}
            rules={{ required: true }}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="digitalSkills"
                name="digitalSkills"
                onChange={handleChange}
              >
                {digitalSkillsOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    checked={value === option.value}
                    value={option.value}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    control={<GreenRadio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
          {getFormError('digitalSkills', errors).hasError && (
            <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
              {getFormError('digitalSkills', errors).message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <Typography style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}>
          What is your reason for choosing the above skill of interest?
          <span style={{ color: 'red' }}>*</span>
        </Typography>

        <Controller
          name="reasonForDigitalSkills"
          control={control}
          rules={{ required: true }}
          render={({ ref, ...rest }) => (
            <TextField
              {...rest}
              placeholder="Reason for digital skills"
              variant="outlined"
              required
              multiline
              rows={5}
              fullWidth
              inputRef={register({
                required: true,
              })}
              onKeyUp={() => trigger('reasonForDigitalSkills')}
              error={getFormError('reasonForDigitalSkills', errors).hasError}
              helperText={getFormError('reasonForDigitalSkills', errors).message}
              size="medium"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
        >
          Please tick the option that best describe your preparation for the program.
        </FormLabel>
        <Typography style={{ fontSize: '12px', fontStyle: 'italic', marginBottom: '10px' }}>
          Select as many as apply to you
        </Typography>
        {renderPreparationOptions(
          'hasLaptop',
          'I have a functional laptop (Has audio & can connect to the internet)',
          handleChange,
          booleanOptions,
        )}
        {renderPreparationOptions(
          'hasPhone',
          'I have a smartphone that can fill-in for a laptop/desktop.',
          handleChange,
          booleanOptions,
        )}
        {renderPreparationOptions(
          'hasInternet',
          'I have good internet connectivity.',
          handleChange,
          booleanOptions,
        )}
        {renderPreparationOptions(
          'hasTime',
          'I have 40 hours a week to dedicate to this program ',
          handleChange,
          booleanOptions,
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            On what platform did you hear about the DFA Challenge?
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="platform"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup {...field} aria-label="platform" name="platform" onChange={handleChange}>
                {platformOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                    checked={value === option.value}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('platform', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('platform', errors).message}
          </p>
        )}
      </Grid>

      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            To qualify for the challenge, you must be following Tech4Dev on any of our social media
            platforms (Facebook, Twitter, and Youtube: @Tech4DevHQ. LinkedIn and Instagram:
            @Tech4Dev). Have you followed us on social media ? *
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="hasFollowedOnSocialMedia"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="hasFollowedOnSocialMedia"
                name="hasFollowedOnSocialMedia"
                onChange={handleChange}
              >
                {booleanOptions.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('hasFollowedOnSocialMedia', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('hasFollowedOnSocialMedia', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <Typography style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}>
          Kindly input your social media handle and the platform where you are following us on?
          (e.g. LinkedIn - @Tech4Dev) *<span style={{ color: 'red' }}>*</span>
        </Typography>

        <Controller
          name="socialMediaHandle"
          control={control}
          rules={{ required: true }}
          render={({ ref, ...rest }) => (
            <TextField
              {...rest}
              placeholder="Enter your social media handle"
              variant="outlined"
              required
              fullWidth
              inputRef={register({
                required: true,
              })}
              onKeyUp={() => trigger('socialMediaHandle')}
              error={getFormError('socialMediaHandle', errors).hasError}
              helperText={getFormError('socialMediaHandle', errors).message}
              size="medium"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          aria-required
        >
          Kindly indicate the frequency at which you. <span style={{ color: 'red' }}>*</span>
        </FormLabel>

        <Box mb={6}>
          {renderFrequencyOptions(
            'accessToDeviceAtHome',
            'Have ACCESS to a digital device (i.e. computer, mobile phone, tablet, etc.) at home or work. ',
            handleChange,
            frequencyOptions,
          )}
        </Box>
        <Box my={6}>
          {renderFrequencyOptions(
            'canUseDigitalDevice',
            'Use a digital device (i.e. computer, mobile phone, tablet, etc.) ON YOUR OWN',
            handleChange,
            frequencyOptions,
          )}
        </Box>
        <Box my={6}>
          {renderFrequencyOptions(
            'accessToInternet',
            'Have ACCESS to the Internet at home or work. ',
            handleChange,
            frequencyOptions,
          )}
        </Box>
        <Box>
          {renderFrequencyOptions(
            'communicateOnInternet',
            'COMMUNICATE with people on the internet',
            handleChange,
            frequencyOptions,
          )}
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
        >
          What devices are you likely to use for accessing the DFA during your learning journey? *
        </FormLabel>
        <Typography style={{ fontSize: '12px', fontStyle: 'italic', marginBottom: '10px' }}>
          Select as many as apply to you 
        </Typography>
        {renderPreparationOptions('isLearnWithDesktop', 'Desktop', handleChange, booleanOptions, 3)}
        {renderPreparationOptions('isLearnWithLaptop', 'Laptop', handleChange, booleanOptions, 3)}
        {renderPreparationOptions('isLearnWithTablet', 'Tablet', handleChange, booleanOptions, 3)}
        {renderPreparationOptions(
          'isLearnWithSmartphone',
          'Smartphone',
          handleChange,
          booleanOptions,
          3,
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            Which of these devices will be your primarily tool for accessing the DFA platform during
            your learning journey? 
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="primaryLearningTool"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="primaryLearningTool"
                name="primaryLearningTool"
                onChange={handleChange}
              >
                {accessTools.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('primaryLearningTool', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('primaryLearningTool', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          aria-required
        >
          On a scale of 1-5 (with 1 being the lowest and 5 as the highest), please indicate the
          level of quality you expect from the DFA platform in terms of the following. 
          <span style={{ color: 'red' }}>*</span>
        </FormLabel>

        <Box mb={6}>
          {renderFrequencyOptions(
            'courseContentEngagementRate',
            'The course content should be interesting/engaging  ',
            handleChange,
            levelOfProgramQuality,
          )}
        </Box>
        <Box mb={6}>
          {renderFrequencyOptions(
            'videoCoursesLength',
            'The videos should not be too long ',
            handleChange,
            levelOfProgramQuality,
          )}
        </Box>
        <Box mb={6}>
          {renderFrequencyOptions(
            'courseContentRelevance',
            'The content should be relevant. ',
            handleChange,
            levelOfProgramQuality,
          )}
        </Box>
        <Box>
          {renderFrequencyOptions(
            'contentIdeasCommunication',
            'The ideas and content in the videos should be clearly communicated. ',
            handleChange,
            levelOfProgramQuality,
          )}
        </Box>
        <Box>
          {renderFrequencyOptions(
            'easyPlatformNavigation',
            'The DFA learning platform should be easy to navigate. ',
            handleChange,
            levelOfProgramQuality,
          )}
        </Box>
        <Box>
          {renderFrequencyOptions(
            'maxSlaTimeForComplaints',
            "Learners' complaints should be easily resolved within 24 hours. ",
            handleChange,
            levelOfProgramQuality,
          )}
        </Box>
        <Box>
          {renderFrequencyOptions(
            'additionReadingMaterials',
            'There should be reading materials to support learning.   ',
            handleChange,
            levelOfProgramQuality,
          )}
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            What learning environment/learning style would you prefer? *
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="preferredLearningEnvironment"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="preferredLearningEnvironment"
                name="preferredLearningEnvironment"
                onChange={handleChange}
              >
                {learningStyle?.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('preferredLearningEnvironment', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('preferredLearningEnvironment', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            What will make the Digital for All Challenge a success for you? 
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="programSuccessMeasure"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="programSuccessMeasure"
                name="programSuccessMeasure"
                onChange={handleChange}
              >
                {programSuccessMeasure?.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('programSuccessMeasure', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('programSuccessMeasure', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            What is the MAIN challenge you expect might slow your progress in your learning journey?
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="mainChallenge"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="mainChallenge"
                name="mainChallenge"
                onChange={handleChange}
              >
                {challenges?.map((option) => (
                  <FormControlLabel
                    checked={value === option.value}
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('mainChallenge', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('mainChallenge', errors).message}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

export default StepFour;
const GreenRadio = withStyles({
  root: {
    color: colors.grey,
    '&$checked': {
      color: '#287D3C',
    },
    marginRight: 10,
    padding: 3,
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
