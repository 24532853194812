import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React from 'react';
import {
  employmentOptions,
  employmentStatusOptions,
  educationLevelOptions,
  booleanOptions,
  incomeOptions,
} from './variables';
import { colors, fontWeight } from '../../../Css';

import { getFormError } from 'utils/formError';
const StepTwo = (props) => {
  const { control, watch, handleChange, register, errors } = props;

  // Watch for changes in the control value
  const isDisabled = watch('isDisabled') === 'Yes' ? true : false;

  const renderDisabilityOption = (label, name, control) => (
    <Grid
      container
      style={{
        display: isDisabled ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
      }}
    >
      <Grid item xs={7} sm={8} md={8} lg={4} style={{ marginRight: '10px' }}>
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
        >
          {label}
        </FormLabel>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={8} style={{ flexWrap: 'nowrap' }}>
        <Controller
          name={name}
          control={control}
          rules={{ required: { value: true, message: 'Required field' } }}
          render={({ field, value }) => (
            <RadioGroup
              {...field}
              aria-label={name}
              name={name}
              onClick={handleChange}
              style={{ flexDirection: 'row' }}
            >
              {booleanOptions.map((option) => (
                <FormControlLabel
                  style={{ color: '#083A55' }}
                  checked={value === option.value}
                  key={option.value}
                  value={option.value}
                  control={<GreenRadio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
        />
        {getFormError(name, errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError(name, errors).message}
          </p>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Typography
        style={{
          fontSize: '16px',
          color: '#083A55',
          margin: '12px 0',
          fontWeight: fontWeight.bold,
        }}
      >
        The information you provide in this section will help us to know you.
      </Typography>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            What do you currently do<span style={{ color: 'red' }}>*</span>? I am
          </FormLabel>
          <Controller
            name="currentlyDoing"
            control={control}
            rules={{ required: true }}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                name="currentlyDoing"
                onClick={handleChange}
                aria-label="currentlyDoing"
              >
                {employmentStatusOptions.map((option) => (
                  <FormControlLabel
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    key={option.value}
                    checked={value === option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('currentlyDoing', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('currentlyDoing', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            Which of the following best describes your employment?{' '}
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="employment"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                name="employment"
                onClick={handleChange}
                aria-label="employment"
              >
                {employmentOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                    checked={value === option.value}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('employment', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('employment', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            What is the highest level of education you have received?{' '}
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="education"
            rules={{ required: true }}
            control={control}
            render={({ field, value }) => (
              <RadioGroup {...field} name="education" onClick={handleChange} aria-label="education">
                {educationLevelOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    checked={value === option.value}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('education', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('education', errors).message}
          </p>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            Are you a person with disability?<span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="isDisabled"
            control={control}
            rules={{ required: true }}
            render={({ field, value }) => {
              return (
                <RadioGroup
                  {...field}
                  onChange={handleChange}
                  name="isDisabled"
                  aria-label="isDisabled"
                  style={{ flexDirection: 'row' }}
                >
                  {booleanOptions?.map((option) => {
                    return (
                      <FormControlLabel
                        key={option.value}
                        style={{
                          color: '#083A55',
                          display: 'flex',
                          alignItems: 'flex-start',
                          width: '100%',
                          marginBottom: '8px',
                        }}
                        value={option.value}
                        control={<GreenRadio />}
                        checked={value === option.value}
                        label={option.label}
                      />
                    );
                  })}
                </RadioGroup>
              );
            }}
          />
        </FormControl>
        {getFormError('isDisabled', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('isDisabled', errors).message}
          </p>
        )}
      </Grid>
      {isDisabled && (
        <Grid item xs={12} style={{ marginTop: '40px' }}>
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            You said you are a Person with Disability. Which of the disability do you have?
            <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Typography style={{ fontSize: '12px', fontStyle: 'italic' }}>
            Select as many as apply to you
          </Typography>
          {renderDisabilityOption('Hearing Impairment', 'isHearingImpaired', control)}
          {renderDisabilityOption('Seeing impairment', 'isVisionImpaired', control)}
          {renderDisabilityOption('Movement impairment', 'isMovementImpaired', control)}
          {renderDisabilityOption('Albinism', 'hasAlbinism', control)}
          {renderDisabilityOption('Others', 'hasOthers', control)}
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
          >
            On average, how much is your monthly Income?<span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <Controller
            name="monthlyIncome"
            control={control}
            rules={{ required: true }}
            render={({ field, value }) => (
              <RadioGroup
                {...field}
                aria-label="monthlyIncome"
                onClick={handleChange}
                name="monthlyIncome"
              >
                {incomeOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    checked={value === option.value}
                    control={<GreenRadio />}
                    style={{
                      color: '#083A55',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      marginBottom: '8px',
                    }}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        {getFormError('monthlyIncome', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('monthlyIncome', errors).message}
          </p>
        )}
      </Grid>
    </Grid>
  );
};

export default StepTwo;
const GreenRadio = withStyles({
  root: {
    color: colors.grey,
    '&$checked': {
      color: '#287D3C',
    },
    marginRight: 10,
    padding: 3,
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
