import { gql } from '@apollo/client';
import { CORE_EXISTING_USER_FULL_FIELDS, CORE_USER_FIELDS } from 'graphql/DFAfragments';

export const ADD_USER = gql`
  mutation CreateDfaUser(
    $firstname: String
    $lastname: String
    $phone: String
    $gender: String!
    $email: String!
    $age: Int!
    $maritalStatus: DfaUserMaritalStatusEnumCreate!
    $stateOfOrigin: String!
    $currentState: String!
    $residentialArea: DfaUserResidentialAreaEnumCreate!
    $currentlyDoing: DfaUserCurrentlyDoingEnumCreate!
    $employment: DfaUserEmploymentEnumCreate!
    $education: DfaUserEducationEnumCreate!
    $isDisabled: Boolean
    $isHearingImpaired: Boolean
    $isVisionImpaired: Boolean
    $isMovementImpaired: Boolean
    $hasAlbinism: Boolean
    $hasOthers: Boolean
    $monthlyIncome: DfaUserMonthlyIncomeEnumCreate!
    $middlename: String
    $extentOfDigitalSkills: DfaUserExtentOfDigitalSkillsEnumCreate!
    $computerAbility: DfaUserComputerAbilityEnumCreate!
    $digitalSkillsTrainingJourney: DfaUserDigitalSkillsTrainingJourneyEnumCreate!
    $digitalParticipation: Boolean
    $learningTrack: DfaUserLearningTrackEnumCreate
    $digitalSkills: DfaUserDigitalSkillsEnumCreate!
    $reasonForDigitalSkills: String!
    $hasLaptop: Boolean
    $hasPhone: Boolean
    $hasInternet: Boolean
    $hasTime: Boolean
    $platform: DfaUserPlatformEnumCreate!
    $category: DfaUserCategoryEnumCreate
    $schoolName: String
    $schoolCategory: DfaUserSchoolCategoryEnumCreate
    $schoolType: DfaUserSchoolTypeEnumCreate
    $genderIntentional: DfaUserGenderIntentionalEnumCreate
    $numberOfSubjects: Int
    $subjectCategory: DfaUserSubjectCategoryEnumCreate
    $subjectName: String
    $certification: String
    $socialMediaHandle: String
    $hasFollowedOnSocialMedia: Boolean
    $accessToDeviceAtHome: DfaUserAccessToDeviceAtHomeEnumCreate
    $canUseDigitalDevice: DfaUserCanUseDigitalDeviceEnumCreate
    $accessToInternet: DfaUserAccessToInternetEnumCreate
    $communicateOnInternet: DfaUserCommunicateOnInternetEnumCreate
    $isLearnWithDesktop: Boolean
    $isLearnWithLaptop: Boolean
    $isLearnWithTablet: Boolean
    $isLearnWithSmartphone: Boolean
    $primaryLearningTool: DfaUserPrimaryLearningToolEnumCreate
    $courseContentEngagementRate: Int
    $videoCoursesLength: Int
    $courseContentRelevance: Int
    $contentIdeasCommunication: Int
    $easyPlatformNavigation: Int
    $maxSlaTimeForComplaints: Int
    $additionReadingMaterials: Int
    $preferredLearningEnvironment: DfaUserPreferredLearningEnvironmentEnumCreate
    $mainChallenge: DfaUserMainChallengeEnumCreate
    $programSuccessMeasure: DfaUserProgramSuccessMeasureEnumCreate
  ) {
    createDfaUser(
      newDfauser: {
        firstname: $firstname
        lastname: $lastname
        middlename: $middlename
        phone: $phone
        gender: $gender
        email: $email
        age: $age
        maritalStatus: $maritalStatus
        stateOfOrigin: $stateOfOrigin
        currentState: $currentState
        residentialArea: $residentialArea
        currentlyDoing: $currentlyDoing
        employment: $employment
        education: $education
        isDisabled: $isDisabled
        isHearingImpaired: $isHearingImpaired
        isVisionImpaired: $isVisionImpaired
        isMovementImpaired: $isMovementImpaired
        hasAlbinism: $hasAlbinism
        hasOthers: $hasOthers
        monthlyIncome: $monthlyIncome
        extentOfDigitalSkills: $extentOfDigitalSkills
        computerAbility: $computerAbility
        digitalSkillsTrainingJourney: $digitalSkillsTrainingJourney
        digitalParticipation: $digitalParticipation
        learningTrack: $learningTrack
        digitalSkills: $digitalSkills
        reasonForDigitalSkills: $reasonForDigitalSkills
        hasLaptop: $hasLaptop
        hasPhone: $hasPhone
        hasInternet: $hasInternet
        hasTime: $hasTime
        platform: $platform
        category: $category
        schoolName: $schoolName
        schoolCategory: $schoolCategory
        schoolType: $schoolType
        genderIntentional: $genderIntentional
        numberOfSubjects: $numberOfSubjects
        subjectCategory: $subjectCategory
        subjectName: $subjectName
        certification: $certification
        socialMediaHandle: $socialMediaHandle
        hasFollowedOnSocialMedia: $hasFollowedOnSocialMedia
        accessToDeviceAtHome: $accessToDeviceAtHome
        canUseDigitalDevice: $canUseDigitalDevice
        accessToInternet: $accessToInternet
        communicateOnInternet: $communicateOnInternet
        isLearnWithDesktop: $isLearnWithDesktop
        isLearnWithLaptop: $isLearnWithLaptop
        isLearnWithTablet: $isLearnWithTablet
        isLearnWithSmartphone: $isLearnWithSmartphone
        primaryLearningTool: $primaryLearningTool
        courseContentEngagementRate: $courseContentEngagementRate
        videoCoursesLength: $videoCoursesLength
        courseContentRelevance: $courseContentRelevance
        contentIdeasCommunication: $contentIdeasCommunication
        easyPlatformNavigation: $easyPlatformNavigation
        maxSlaTimeForComplaints: $maxSlaTimeForComplaints
        additionReadingMaterials: $additionReadingMaterials
        preferredLearningEnvironment: $preferredLearningEnvironment
        mainChallenge: $mainChallenge
        programSuccessMeasure: $programSuccessMeasure
      }
    ) {
      ok
      errors {
        messages
        field
      }
      dfauser {
        id
      }
    }
  }
`;

export const ACTIVATE_USERS = gql`
  mutation activateUsers($userIds: [UUID]) {
    activateUsers(userIds: $userIds) {
      ok
      success {
        messages
        field
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const DEACTIVATE_USERS = gql`
  mutation deactiveUsers($userIds: [UUID]) {
    deactivateUsers(userIds: $userIds) {
      ok
      success {
        messages
        field
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      ok
      user {
        id
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const DELETE_MULTI_USER = gql`
  mutation deleteUsers($userIds: [UUID]!) {
    deleteUsers(userIds: $userIds) {
      ok
      success {
        messages
        field
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation resendVerification($userId: UUID!) {
    resendVerification(userId: $userId) {
      ok
      success {
        messages
        field
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const DOWNLOAD_PARTICIPANTS = gql`
  mutation downloadDfauser($institutionId: UUID) {
    downloadDfauser(institutionId: $institutionId) {
      ok
      success {
        messages
        field
      }
      errors {
        messages
        field
      }
    }
  }
`;
