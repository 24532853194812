import { useRoleNavigation } from 'hooks/DFA/useRoleNavigation';
import React, { useEffect } from 'react';

const Redirects = () => {
	const { handleRouteNavigation } = useRoleNavigation()

	useEffect(() => {
		handleRouteNavigation()
	}, [handleRouteNavigation])

	return <></>
};

export default Redirects;
