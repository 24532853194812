import MaxWidthContainer from 'reusables/MaxWidthContainer';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as Logo } from 'assets/svgs/prunedge.svg';
import { ReactComponent as Lms } from 'assets/svgs/LMS.svg';
import { colors } from '../../Css';

const DFAFooter = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      bgcolor={'white'}
      style={{
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        zIndex: 999,
        height: '50px',
        backgroundColor: colors.white,
        width: '100% !important',
      }}
    >
      <MaxWidthContainer>
        <Box
          display={isSmallScreen ? 'block' : 'flex'}
          alignItems="center"
          justifyContent={'space-between'}
          py={6}
          sx={{ backgroundColor: 'white' }}
        >
          <Box display="flex" alignItems="center">
            <Typography style={{ fontSize: '14px', color: '#6B6C7E' }}>
              © Digital for All Challenge 2024. All right reserved.
            </Typography>
          </Box>
          <Typography
            style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: '#6B6C7E' }}
          >
            Powered by
            <Logo style={{ paddingLeft: '4px' }} />
            <Lms style={{ paddingLeft: '4px' }} />
          </Typography>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
};

export default DFAFooter;
