import { AssessmentCompletionStatus, AssessmentQuestionType, AssessmentType } from '../constants';
import { convertTimeSpentToDuration } from 'utils/TransformationUtils';
import { UserRoles, TrackList } from 'utils/constants';
import { addDays, differenceInHours, differenceInMinutes, parseISO } from 'date-fns';

export const AssessmentFailureReasons = {
	START_DATE_NOT_REACHED: 'START_DATE_NOT_REACHED',

	END_DATE_PASSED: 'END_DATE_PASSED',

	CANNOT_TAKE_MORE_THAN_ONCE: 'CANNOT_TAKE_MORE_THAN_ONCE',
	ASSESSMENT_COMPLETED: 'ASSESSMENT_COMPLETED',
	TIME_ELAPSED: 'TIME_ELAPSED',
	RETAKE_ASSESSMENT_AFTER_24_HOURS: 'RETAKE_ASSESSMENT_AFTER_24_HOURS',
	TAKE_COURSE: 'TAKE_COURSE',
	MUST_COMPLETE_TRACK_COURSE: 'Must complete track course',
	HAVE_EXCEEDED_NUMBER_OF_RETRIES: 'Have exceeded number of retries',
	NOT_QUALIFIED_FOR_BASIC_ASSESSMENT: 'Not Qualified for Basic Assessment',
	NOT_QUALIFIED_FOR_INTERMEDIATE_ASSESSMENT: 'Not Qualified for Intermediate Assessment',
	NOT_QUALIFIED_FOR_TRACK_ASSESSMENT: 'Not Qualified for Track Assessment',
};

export const AssessmentButtonRenderReasons = {
	START_ASSESSMENT: 'Start Assessment',

	CONTINUE_ASSESSMENT: 'Continue Assessment',

	RETAKE_ASSESSMENT: 'Retake Assessment',
};

export const canTakeAssessment = ({
	startDate,

	startTime,

	dueDate,

	dueTime,

	completionStatus,

	startedAt,

	duration,

	retakeGeneralAssessmentAt,
	endTime,
	canRetakeTrackAssessmentAt,
	assessmentType,
	trackAssessmentCourseNumberOfRetries,
	trackAssessmentNumberOfRetries,
	currentTrack,
}) => {
	let isActiveTimeWithinAssessmentDateRangeResult = isCurrentTimeWithinGeneralAssessmentDateRange({
		startDate,

		startTime,

		dueDate,

		dueTime,

		retakeGeneralAssessmentAt,
	});
	// let hasAssessmentBeenCompletedResult = hasAssessmentBeenCompleted(completionStatus);
	let canRetakeAssessmentAfter24HoursResult = canRetakeAssessmentAfter24Hours(
		endTime,
		retakeGeneralAssessmentAt,
		canRetakeTrackAssessmentAt,
		currentTrack,
	);
	// let canTakeBasicAssessmentAfterNumberOfRetriesResult = canTakeBasicAssessmentAfterNumberOfRetries(
	//   assessmentType,
	//   trackAssessmentCourseNumberOfRetries,
	//   trackAssessmentNumberOfRetries,
	// );
	// let isBasicAssessmentResult = isBasicAssessment(assessmentType);
	let hasTimeElapsedResult = hasTimeElapsed(dueDate, dueTime);
	let result = [
		isActiveTimeWithinAssessmentDateRangeResult,
		// proceedToTrackResult,
		// hasAssessmentBeenCompletedResult,

		hasTimeElapsedResult,
		canRetakeAssessmentAfter24HoursResult,
		// canTakeBasicAssessmentAfterNumberOfRetriesResult,
	].find((validator) => !validator.value);

	if (result) return result;

	return {
		value: true,

		reason: null,
	};
};

export const passedGeneralAssesment = (scorePercentage) => {
	if (scorePercentage < 65) {
		return false;
	}
	return true;
};

export const canTakeGeneralAssessment = (dfaUser, UserRole) => {
	//This is used to route the user to the assessment page when login is successful
	// DO NOT ADD TO THE ASSESSMENTRELOADED
	//Note: dfaUser?.hasStartedGeneralAssessment === true means that the general assessment has been started
	switch (UserRole) {
		case UserRoles.YOUTH:
			if (
				dfaUser?.proceedToTrack === false &&
				dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT
			) {
				return true;
			}
			//when youth takes the assessment and failes and refuse enrol for bdl but just logs out bring him back to the view to choose
			//FOR THE GENERAL ASSESSMENT
			// if (
			//   dfaUser?.hasStartedGeneralAssessment === false &&
			//   dfaUser?.proceedToTrack === true &&
			//   dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT
			// ) {
			//   return true;
			// }
			return false;

		case UserRoles.K12_TEACHER:
			// Add logic for K12_TEACHER role here
			if (dfaUser?.proceedToTrack === false && dfaUser?.showK12Dashboard === false) {
				return true;
			}

			return false;

		case UserRoles.CIVIL_SERVANT:
			// Add logic for SOME_OTHER_ROLE role here
			if (dfaUser?.proceedToTrack === false) {
				return true;
			}
			return false;

		case UserRoles.K12_STUDENT:
			// Add logic for SOME_OTHER_ROLE role here
			if (
				dfaUser?.attemptedTrackAssessment === false &&
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
			) {
				return true;
			}
			// Add logic for SOME_OTHER_ROLE role here
			if (
				dfaUser?.canTakeTrackAssessment === true &&
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
			) {
				return true;
			}

			return false;

		default:
			return false;
	}
};
export const isPassedGeneralAssessment = (dfaUser, UserRole) => {
	//for knowing the view to display whether they pass or failed
	// DO NOT ADD TO ASSESSMENTRELOAD
	switch (UserRole) {
		case UserRoles.YOUTH:
			// passed the general assessment
			if (dfaUser?.isPassedGeneralAssessment === true) {
				return true;
			}
			return false;
		case UserRoles.K12_TEACHER:
			// Add logic for K12_TEACHER role here
			if (dfaUser?.isPassedGeneralAssessment === true) {
				return true;
			}
			return false;
		case UserRoles.CIVIL_SERVANT:
			// Add logic for SOME_OTHER_ROLE role here
			//TOD0: check the view when a civil servant fails an assessment
			if (dfaUser?.hasPassedGeneralAssessment === true) {
				return true;
			}
			return false;
		case UserRoles.K12_STUDENT:
			// Add logic for SOME_OTHER_ROLE role here
			if (dfaUser?.isPassedTrackAssessment === true) {
				return true;
			}
			return false;

		default:
			return false; // Default case if the user role is not recognized
	}
};

export const canViewStudentDashboard = (dfaUser, UserRole) => {
	switch (UserRole) {
		case UserRoles.YOUTH:
			if (
				dfaUser?.proceedToTrack === true &&
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
			) {
				return true;
			}
			if (
				dfaUser?.proceedToTrack === true &&
				Object.values(TrackList).includes(dfaUser?.currentTrack) &&
				dfaUser?.hasPassedTrackGeneralAssessment
			) {
				return true;
			}
			if (dfaUser?.proceedToTrack === true && dfaUser?.hasPassedTrackGeneralAssessment) {
				return true;
			}
			return false;
		case UserRoles.K12_TEACHER:
			if (dfaUser?.showK12Dashboard === true) {
				return true;
			}
			return false;
		case UserRoles.CIVIL_SERVANT:
			if (dfaUser?.proceedToTrack === true) {
				return true;
			}
			return false;
		case UserRoles.K12_STUDENT:
			return false;

		default:
			return false; // Default case if the user role is not recognized
	}
};

// Check for has_passed_track_general_assessment
export const canTakeBDLBasicAssessment = (dfaUser, UserRole) => {
	//ADDED TO THE ASSESSMENTRELOADED
	switch (UserRole) {
		case UserRoles.YOUTH:
			// Add logic for SOME_OTHER_ROLE role here
			if (
				dfaUser?.canTakeTrackAssessment === true &&
				dfaUser?.mustTakeTrackCourse === false &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
			) {
				return { value: true, reason: null };
			}
			//for the final youth assessment
			if (
				dfaUser?.canTakeTrackAssessment === true &&
				dfaUser?.mustTakeTrackCourse === false &&
				dfaUser?.proceedToTrack === true &&
				Object.values(TrackList).includes(dfaUser?.currentTrack)
			) {
				return { value: true, reason: null };
			}
			if (dfaUser?.canTakeTrackAssessment === false && dfaUser?.mustTakeTrackCourse === true) {
				//user must complete the track course before taking the assessment :TODo add reason to the response
				return { value: false, reason: AssessmentFailureReasons.MUST_COMPLETE_TRACK_COURSE };
			}
			if (
				//user has exceeded the number of times they cantake the course and assessment
				dfaUser?.canTakeTrackAssessment === false &&
				dfaUser?.mustTakeTrackCourse === false
			) {
				return { value: false, reason: AssessmentFailureReasons.HAVE_EXCEEDED_NUMBER_OF_RETRIES };
			}
			return { value: false, reason: AssessmentFailureReasons.NOT_QUALIFIED_FOR_BASIC_ASSESSMENT };
		// return { value: true, reason: null };
		case UserRoles.K12_TEACHER:
			// it means the user has tried to take the general assesment three times and they’ve
			//failed three times.So it means when they sign in they will be directed to the dashboard and they will see the basic course and basic assessment on their dahboard
			if (dfaUser?.proceedToTrack === false && dfaUser?.showK12Dashboard === true) {
				return { value: true, reason: null };
			}
			// Add logic for SOME_OTHER_ROLE role here
			if (dfaUser?.canTakeTrackAssessment === true && dfaUser?.mustTakeTrackCourse === false) {
				return { value: true, reason: null };
			}
			if (dfaUser?.canTakeTrackAssessment === false && dfaUser?.mustTakeTrackCourse === true) {
				//user must complete the track course before taking the assessment :TODo add reason to the response
				return { value: false, reason: AssessmentFailureReasons.MUST_COMPLETE_TRACK_COURSE };
			}
			if (
				//user has exceeded the number of times they cantake the course and assessment
				dfaUser?.canTakeTrackAssessment === false &&
				dfaUser?.mustTakeTrackCourse === false
			) {
				return { value: false, reason: AssessmentFailureReasons.HAVE_EXCEEDED_NUMBER_OF_RETRIES };
			}
			return { value: false, reason: AssessmentFailureReasons.NOT_QUALIFIED_FOR_BASIC_ASSESSMENT };
		// return { value: true, reason: null };
		case UserRoles.CIVIL_SERVANT:
			// Add logic for SOME_OTHER_ROLE role here
			if (dfaUser?.canTakeTrackAssessment === true && dfaUser?.mustTakeTrackCourse === false) {
				return { value: true, reason: null };
			}
			if (dfaUser?.canTakeTrackAssessment === false && dfaUser?.mustTakeTrackCourse === true) {
				//user must complete the track course before taking the assessment :TODo add reason to the response
				return { value: false, reason: AssessmentFailureReasons.MUST_COMPLETE_TRACK_COURSE };
			}
			if (
				//user has exceeded the number of times they cantake the course and assessment
				dfaUser?.canTakeTrackAssessment === false &&
				dfaUser?.mustTakeTrackCourse === false
			) {
				return { value: false, reason: AssessmentFailureReasons.HAVE_EXCEEDED_NUMBER_OF_RETRIES };
			}
			return { value: false, reason: AssessmentFailureReasons.NOT_QUALIFIED_FOR_BASIC_ASSESSMENT };
		// return { value: true, reason: null };
		case UserRoles.K12_STUDENT:
			return { value: false, reason: AssessmentFailureReasons.NOT_QUALIFIED_FOR_BASIC_ASSESSMENT };

		default:
			return { value: true, reason: null };
	}
};

export const canRetakeAssessment = (userRole, dfaUserData) => {
	switch (userRole) {
		case UserRoles.YOUTH:
			if (
				Date.now() > new Date(dfaUserData?.canRetakeTrackAssessmentAt).getTime() &&
				dfaUserData?.mustTakeTrackCourse === false
			) {
				return canTakeBDLBasicAssessment(dfaUserData, userRole);
			}
			return { value: false, reason: 'You cannot retake the course yet' };

		default:
			return { value: true, reason: null };
	}
};

export const isBDLBasicAssessment = (dfaUser) => {
	if (
		dfaUser?.proceedToTrack === true &&
		dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT &&
		dfaUser?.hasStartedTrackGeneralAssessment === true
	) {
		return false;
	}
	return true;
};

export const isCompletedGeneralAssessment = (dfaUser, userRole) => {
	switch (userRole) {
		case UserRoles.YOUTH:
			// Add logic for SOME_OTHER_ROLE role here
			if (
				Object.values(TrackList).includes(dfaUser?.currentTrack) ||
				(dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT &&
					dfaUser?.hasStartedGeneralAssessment === true &&
					dfaUser?.proceedToTrack === true)
				//  &&
				// dfaUser?.mustTakeTrackCourse === true
			) {
				return true;
			}

			return false;
		case UserRoles.K12_TEACHER:
			if (
				// dfaUser?.proceedToTrack === false &&
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT &&
				Object.values(TrackList).includes(dfaUser?.currentTrack) &&
				dfaUser?.hasStartedTrackGeneralAssessment === false
			) {
				return false;
			}
			// return { value: false, reason: AssessmentFailureReasons.NOT_QUALIFIED_FOR_BASIC_ASSESSMENT };
			return true;
		case UserRoles.CIVIL_SERVANT:
			if (
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT &&
				dfaUser?.hasStartedGeneralAssessment === true &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.mustTakeTrackCourse === true
			) {
				return true;
			}
			return false;
		case UserRoles.K12_STUDENT:
			return true;
		default:
			return false;
	}
};

export const canViewDashboard = (dfaUser, userRole) => {
	switch (userRole) {
		case UserRoles.YOUTH:
			if (
				(dfaUser?.canTakeTrackAssessment === true ||
					dfaUser?.canRetakeTrackAssessmentAt !== null) &&
				dfaUser?.proceedToTrack === true
			) {
				return true;
			}
			return false;
		case UserRoles.K12_TEACHER:
			if (
				(dfaUser?.canTakeTrackAssessment === true ||
					dfaUser?.canRetakeTrackAssessmentAt !== null) &&
				dfaUser?.proceedToTrack === true
			) {
				return true;
			}
			if (dfaUser?.showK12Dashboard === true) {
				return true;
			}
			return false;
		case UserRoles.CIVIL_SERVANT:
			if (
				(dfaUser?.canTakeTrackAssessment === true ||
					dfaUser?.canRetakeTrackAssessmentAt !== null) &&
				dfaUser?.proceedToTrack === true
			) {
				return true;
			}
			return false;
		case UserRoles.K12_STUDENT:
			if (
				(dfaUser?.canTakeTrackAssessment === true ||
					dfaUser?.canRetakeTrackAssessmentAt !== null) &&
				dfaUser?.proceedToTrack === true
			) {
				return true;
			}
			return false;
		default:
			return false;
	}
};
// Check for has_passed_track_general_assessment
export const hasPassedBDLBasicAssessment = (dfaUser, UserRole) => {
	//ADDED TO THE ASSESSMENTRELOADED
	switch (UserRole) {
		case UserRoles.YOUTH:
			//FOR THE BASIC ASSESSMENT IF HE ENROLLS FOR BASIC LEVEL
			if (
				dfaUser?.isPassedTrackAssessment === false &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
			) {
				return false;
			}
			//FOR THE GENERAL ASSESSMENT
			if (
				dfaUser?.isPassedTrackAssessment === false &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT
			) {
				return false;
			}
			return true;
		// case UserRoles.YOUTH:
		//   // Add logic for SOME_OTHER_ROLE role here
		//   if (
		//     dfaUser?.canTakeTrackAssessment === true &&
		//     dfaUser?.mustTakeTrackCourse === false &&
		//     dfaUser?.proceedToTrack === true &&
		//     dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
		//   ) {
		//     return { value: true, reason: null };
		//   }
		//   if (dfaUser?.canTakeTrackAssessment === false && dfaUser?.mustTakeTrackCourse === true) {
		//     //user must complete the track course before taking the assessment :TODo add reason to the response
		//     return { value: false, reason: AssessmentFailureReasons.MUST_COMPLETE_TRACK_COURSE };
		//   }
		//   if (
		//     //user has exceeded the number of times they cantake the course and assessment
		//     dfaUser?.canTakeTrackAssessment === false &&
		//     dfaUser?.mustTakeTrackCourse === false
		//   ) {
		//     return { value: false, reason: AssessmentFailureReasons.HAVE_EXCEEDED_NUMBER_OF_RETRIES };
		//   }
		//   // return { value: false, reason: AssessmentFailureReasons.NOT_QUALIFIED_FOR_BASIC_ASSESSMENT };
		//   return { value: true, reason: null };

		// case UserRoles.K12_TEACHER:
		//   // Add logic for K12_TEACHER role here
		//   //dfaUser?.showK12dashboard === false && dfaUser?.proceedToTrack === false means he has not passed but need to take the course
		//   // Add logic for SOME_OTHER_ROLE role here
		//   if (
		//     dfaUser?.canTakeTrackAssessment === true &&
		//     dfaUser?.mustTakeTrackCourse === false &&
		//     dfaUser?.proceedToTrack === true &&
		//     dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
		//   ) {
		//     return { value: true, reason: null };
		//   }
		//   if (dfaUser?.canTakeTrackAssessment === false && dfaUser?.mustTakeTrackCourse === true) {
		//     //user must complete the track course before taking the assessment :TODo add reason to the response
		//     return { value: false, reason: AssessmentFailureReasons.MUST_COMPLETE_TRACK_COURSE };
		//   }
		//   if (
		//     //user has exceeded the number of times they cantake the course and assessment
		//     dfaUser?.canTakeTrackAssessment === false &&
		//     dfaUser?.mustTakeTrackCourse === false
		//   ) {
		//     return { value: false, reason: AssessmentFailureReasons.HAVE_EXCEEDED_NUMBER_OF_RETRIES };
		//   }
		//   // return { value: false, reason: AssessmentFailureReasons.NOT_QUALIFIED_FOR_BASIC_ASSESSMENT };
		//   return { value: true, reason: null };

		case UserRoles.CIVIL_SERVANT:
			if (
				dfaUser?.isPassedTrackAssessment === false &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT
			) {
				return false;
			}
			return true;
		default:
			return true;
	}
};
// Elseif  proceed_to_track is True and  current_track  in  [ 'CYBER_SECURITY', 'PRODUCT_DESIGN', 'DATA_ANALYSIS', 'PRODUCT_DEVELOPMENT',  'SOFTWARE_DEVELOPMENT']

export const canTakeIntermediateTrackGeneralAssessment = (dfaUser, UserRole) => {
	switch (UserRole) {
		case UserRoles.YOUTH:
			//i don't need to pass the general assessment for both youth and civil servant
			if (
				// dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT &&
				Object.values(TrackList).includes(dfaUser?.currentTrack) &&
				dfaUser?.proceedToTrack === true &&
				// dfaUser?.isPassedGeneralAssessment &&
				dfaUser?.hasPassedTrackGeneralAssessment === false
				// &&
				// dfaUser?.hasStartedTrackGeneralAssessment
			) {
				return true;
			}
			return false;
		default:
			return false; // Default case
	}
};
export const hasPassedIntermediateTrackGeneralAssessment = (dfaUser, UserRole) => {
	switch (UserRole) {
		case UserRoles.YOUTH:
			//i don't need to pass the general assessment for both youth and civil servant
			//YOUTH HAS PASSED INTERMEDIATE GENERAL ASSESSMENT the first assessment after selecting the path
			if (
				// dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT &&
				dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.isPassedGeneralAssessment === true
			) {
				return true;
			}
			return false;
		default:
			return false; // Default case
	}
};

export const hasPassedIntermediateAssessment = (dfaUser, UserRole, ignoreCanTakeTrackAssessment = false) => {
	switch (UserRole) {
		case UserRoles.YOUTH:
			//i don't need to pass the general assessment for both youth and civil servant
			if (
				// dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT &&
				(dfaUser?.proceedToTrack &&
					dfaUser?.isPassedGeneralAssessment &&
					dfaUser?.hasPassedTrackGeneralAssessment &&
					//this makes it only visible whenever the user us taking the track general assessment
					dfaUser?.canTakeTrackAssessment === false && !ignoreCanTakeTrackAssessment) || (dfaUser?.proceedToTrack &&
						dfaUser?.isPassedGeneralAssessment &&
						dfaUser?.hasPassedTrackGeneralAssessment)
				// &&
				// dfaUser?.hasStartedTrackGeneralAssessment
			) {
				return true;
			}
			return false;
		default:
			return false; // Default case
	}
};

export const hasPassedIntermediateTrackAssessment = (dfaUser, UserRole) => {
	switch (UserRole) {
		case UserRoles.YOUTH:
			//i don't need to pass the general assessment for both youth and civil servant
			//THE TRACK ASSESSMENT AFTER TAKING THE COURSE
			if (
				// dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT &&
				dfaUser?.proceedToTrack &&
				dfaUser?.isPassedGeneralAssessment &&
				dfaUser?.isPassedTrackAssessment
				// &&
				// dfaUser?.hasStartedTrackGeneralAssessment
			) {
				return true;
			}
			if (dfaUser?.proceedToTrack === true && dfaUser?.isPassedTrackAssessment === true) {
				return true;
			}
			return false;
		default:
			return false; // Default case
	}
};
export const canTakeIntermediateAssessment = (dfaUser, UserRole) => {
	switch (UserRole) {
		case UserRoles.YOUTH:
			//i don't need to pass the general assessment for both youth and civil servant
			//has passed but has not started the intermediate asssessment
			if (
				dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.isPassedGeneralAssessment === true &&
				// dfaUser?.hasPassedTrackGeneralAssessment
				dfaUser?.hasStartedTrackGeneralAssessment === false
			) {
				return true;
			}
			return false;
		default:
			return false; // Default case
	}
};

export const isEligibleK12Teacher = (dfaUser, UserRole) => {
	switch (UserRole) {
		case UserRoles.K12_TEACHER:
			//when the user has taken the general assessment and passed
			if (
				dfaUser?.showK12Dashboard === true &&
				dfaUser?.proceedToTrack === true &&
				dfaUser?.isPassedGeneralAssessment === true
			) {
				return true;
			}
			//user failed the general assessment but has taken the course and the course track assessment and passed
			if (
				dfaUser?.showK12Dashboard === true &&
				dfaUser?.proceedToTrack === true
				//TODO: look ofr the field to show the k12 user has passed the basic assessment
			) {
				return true;
			}
			//Note that at no point or the other can proceed_to_track is True and show_k12_dashboard will be False
			return false;
		default:
			return false; // Default case
	}
};
// export const isIntermediateAssessmentEligible = (dfaUser) => {
//   const eligible =
//     dfaUser?.currentTrack === AssessmentType.GENERAL_ASSESSMENT &&
//     dfaUser?.proceedToTrack &&
//     dfaUser?.isPassedGeneralAssessment &&
//     (dfaUser?.hasStartedTrackGeneralAssessment || false);

//   return eligible;
// };

export const iswithinGeneralAssessmentTimeLine = (
	hasStartedGeneralAssessment,
	assessmentGrade,
	intermediateAssessment,
	hasStartedTrackGeneralAssessment,
) => {
	if (intermediateAssessment) {
		if (
			hasStartedTrackGeneralAssessment === true &&
			Date.now() <= new Date(assessmentGrade?.endTime).getTime()
		) {
			return AssessmentButtonRenderReasons.CONTINUE_ASSESSMENT;
		} else if (hasStartedTrackGeneralAssessment === false) {
			return AssessmentButtonRenderReasons.START_ASSESSMENT;
		} else {
			return AssessmentButtonRenderReasons.RETAKE_ASSESSMENT;
		}
	} else {
		if (
			hasStartedGeneralAssessment === true &&
			Date.now() <= new Date(assessmentGrade?.endTime).getTime()
		) {
			return AssessmentButtonRenderReasons.CONTINUE_ASSESSMENT;
		} else if (hasStartedGeneralAssessment === false) {
			return AssessmentButtonRenderReasons.START_ASSESSMENT;
		} else {
			return AssessmentButtonRenderReasons.RETAKE_ASSESSMENT;
		}
	}
};

export const countDownTimer = (startedAt, duration, callbackFunction) => {
	let totalDurationInSeconds = Number(
		((getActualEndTime({ startedAt, duration }) - Date.now()) / 1000).toFixed(),
	);

	let secondsCount = 0;

	let intervalRef = setInterval(() => {
		if (totalDurationInSeconds <= secondsCount) {
			callbackFunction({ durationLeftInSeconds: 0, done: true });

			clearInterval(intervalRef);

			return;
		}

		let durationLeftInSeconds = totalDurationInSeconds - secondsCount;

		callbackFunction({ durationLeftInSeconds, done: false });

		secondsCount++;
	}, 1000);

	return intervalRef;
};

export const generalAssessmentCountDownTimer = (endTime, callbackFunction) => {
	let totalDurationInSeconds = Number(
		((new Date(endTime).getTime() - new Date().getTime()) / 1000).toFixed(),
	);

	let secondsCount = 0;

	let intervalRef = setInterval(() => {
		if (totalDurationInSeconds <= secondsCount) {
			callbackFunction({ durationLeftInSeconds: 0, done: true });

			clearInterval(intervalRef);

			return;
		}

		let durationLeftInSeconds = totalDurationInSeconds - secondsCount;

		callbackFunction({ durationLeftInSeconds, done: false });

		secondsCount++;
	}, 1000);

	return intervalRef;
};

export const formatAssessmentSubmissionsResponse = (submissions) => {
	// Check if submissions is defined and not null
	if (submissions) {
		const result = submissions.reduce((acc, submission) => {
			// Check if question is null before processing
			if (submission.question !== null) {
				let {
					question: { type: questionType, id: questionId },
					option,
					answer,
				} = submission;

				acc[questionId] =
					questionType === AssessmentQuestionType?.MULTI_CHOICE
						? option?.id
						: {
							html: answer,
							editorState: null,
						};
			}

			return acc;
		}, {});

		// Check if the result is empty and return a default value (empty array in this case)
		return Object.keys(result || {}).length > 0 ? result : [];
	}

	// Return a default value if submissions is undefined or null
	return [];
};

export const isCurrentTimeWithinAssessmentDateRange = ({
	startDate,

	startTime,

	dueDate,

	dueTime,
}) => {
	const actualStartDate = new Date(`${startDate} ${startTime}`).getTime();

	const actualDueDate = new Date(`${dueDate} ${dueTime}`).getTime();

	const currentDate = Date.now();

	const startTimeDifference = actualStartDate - currentDate;

	const endTimeDifference = actualDueDate - currentDate;

	if (startTimeDifference > 0) {
		return { value: false, reason: AssessmentFailureReasons.START_DATE_NOT_REACHED };
	} else if (endTimeDifference <= 0) {
		return { value: false, reason: AssessmentFailureReasons.END_DATE_PASSED };
	}

	return {
		value: true,

		reason: null,
	};
};

export const isCurrentTimeWithinGeneralAssessmentDateRange = ({
	startDate,

	startTime,

	dueDate,

	dueTime,

	retakeGeneralAssessmentAt,
}) => {
	const actualStartDate = new Date(`${startDate} ${startTime}`).getTime();

	const actualDueDate = new Date(`${dueDate} ${dueTime}`).getTime();

	const expectedStartDate = new Date(retakeGeneralAssessmentAt).getTime();

	const currentDate = Date.now();

	const startTimeDifference = actualStartDate - currentDate;

	const endTimeDifference = actualDueDate - currentDate;

	const expectedStartTimeDifference = expectedStartDate - currentDate;

	if (startTimeDifference > 0) {
		return { value: false, reason: AssessmentFailureReasons.START_DATE_NOT_REACHED };
	} else if (endTimeDifference <= 0) {
		return { value: false, reason: AssessmentFailureReasons.END_DATE_PASSED };
	} else if (expectedStartTimeDifference > 0) {
		// check if the user can retake the couse afte 24hours

		return { value: false, reason: AssessmentFailureReasons.START_DATE_NOT_REACHED };
	}

	return {
		value: true,

		reason: null,
	};
};

export const randomizeOptions = (options = []) => {
	let results = [];

	let copiedOptions = [...options];

	for (let i = 0; i < options.length; i++) {
		let selectedIndex = Math.ceil(Math.random() * (copiedOptions.length - 1));

		results.push(copiedOptions[selectedIndex]);

		copiedOptions.splice(selectedIndex, 1);
	}

	return results;
};

export const canRetakeAssessmentAfter24Hours = (
	endTime,
	retakeGeneralAssessmentAt,
	canRetakeTrackAssessmentAt,
	currentTrack,
) => {
	let retakeTime; // Define retakeTime outside the if blocks
	if (currentTrack === AssessmentType.GENERAL_ASSESSMENT) {
		// Convert the assessment endTime to a Date object
		retakeTime = new Date(retakeGeneralAssessmentAt);
	} else if (
		Object.values(TrackList).includes(currentTrack) ||
		currentTrack === AssessmentType.BASIC_ASSESSMENT
	) {
		// Convert the assessment endTime to a Date object
		retakeTime = new Date(canRetakeTrackAssessmentAt);
	} else {
		//for assessments that are not general assessment
		if (
			(retakeGeneralAssessmentAt === null &&
				canRetakeTrackAssessmentAt === null &&
				Object.values(TrackList).includes(currentTrack)) ||
			currentTrack === AssessmentType.BASIC_ASSESSMENT ||
			currentTrack === AssessmentType.GENERAL_ASSESSMENT
		) {
			const today = new Date(endTime);
			retakeTime = addDays(today, 1);
		}
	}

	// Get the current time
	const currentTime = new Date();

	if (currentTime < retakeTime) {
		return {
			value: false,
			reason: AssessmentFailureReasons.RETAKE_ASSESSMENT_AFTER_24_HOURS,
		};
	}

	return {
		value: true,
		reason: null,
	};
};

const hasTimeElapsed = (dueDate, dueTime) => {
	// let actualEndTime = getActualEndTime({ startedAt, duration });

	const actualEndTime = new Date(`${dueDate} ${dueTime}`).getTime();
	let currentTime = Date.now();

	if (currentTime >= actualEndTime) {
		return {
			value: false,

			reason: AssessmentFailureReasons.TIME_ELAPSED,
		};
	}

	return {
		value: true,

		reason: null,
	};
};

export const canTakeBasicAssessment = (dfaUser, userRole) => {
	if (
		dfaUser?.currentTrack === AssessmentType.BASIC_ASSESSMENT &&
		userRole === UserRoles.K12_STUDENT
	) {
		return true;
	}
	return false;
};

const getActualEndTime = ({ startedAt, duration }) => {
	let startedAtToMilliseconds = new Date(startedAt).getTime();

	let durationToMilliseconds = duration * 60 * 1000;

	return startedAtToMilliseconds + durationToMilliseconds;
};
