import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Radio, Button, Typography, makeStyles, withStyles } from '@material-ui/core';

import { ReactComponent as EnvelopeIcon } from 'assets/svgs/envelope-outlined.svg';
import { ReactComponent as PhoneIcon } from 'assets/svgs/phone-outlined.svg';
import { convertToSentenceCase } from 'utils/TransformationUtils';
import { green } from '@material-ui/core/colors';
import { colors } from '../../../Css';

export const AuthenticationMethod = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

const DFAAuthenticationMethodPanel = ({ method, checked, onClick, value }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.container}
      variant="outlined"
      color={checked ? green[700] : 'default'}
      onClick={() => onClick(!checked)}
    >
      <Box p={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {method === AuthenticationMethod.EMAIL ? <EnvelopeIcon /> : <PhoneIcon />}
          <GreenRadio checked={checked} />
        </Box>
        <Box my={6}>
          <Typography variant="body1" color="textPrimary">
            {convertToSentenceCase(method)} Authentication
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Send OTP to the{' '}
            {method === AuthenticationMethod.EMAIL ? 'email address' : 'phone number'} below
          </Typography>
        </Box>
        <Box mt={6}>
          <Typography variant="body2" color="textPrimary">
            {value}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

const useStyles = makeStyles({
  container: {
    padding: 0,
    width: '100%',
    height: '100%',
    textAlign: 'initial',
  },
});

DFAAuthenticationMethodPanel.propTypes = {
  method: PropTypes.oneOf(Object.values(AuthenticationMethod)),
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
};

const GreenRadio = withStyles({
  root: {
    color: colors.grey,
    '&$checked': {
      color: '#287D3C',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default memo(DFAAuthenticationMethodPanel);
