import { makeStyles, TextField, Typography } from '@material-ui/core';
import AuthLayout from 'Layout/AuthLayout';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import LoadingButton from 'reusables/LoadingButton';
import { useNotification } from 'reusables/NotificationBanner';
import { PublicPaths } from 'routes';
import { EMAIL_REGEX } from 'utils/constants';
import logoSvg from 'assets/svgs/newDFA-logo.svg';
import { colors, fontFamily, fontSizes, fontWeight, spaces } from '../../../Css';
import { useLazyQuery } from '@apollo/client';
import { GET_EXISTING_USER } from 'graphql/queries/DFA/auth';
import useSubdomain from 'hooks/useSubDomain';
import DFAAuthLayout from 'Layout/DFALayout/DFAAuthLayout';
import { green } from '@material-ui/core/colors';
import theme from 'theme';

const DFAForgotPassword = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const notification = useNotification();
  const history = useHistory();
  const { domainObject } = useSubdomain();
  const institutionId = domainObject?.id;

  const [existingUser, { loading }] = useLazyQuery(GET_EXISTING_USER, {
    onError: (error) => {
      notification.error({
        message: error?.message,
      });
    },
    onCompleted: ({ existingUser }) => {
      history.push(
        `${PublicPaths.EXISTING_USER_OTP_VERIFICATION}/?identifier=${
          existingUser?.matricNumber || existingUser?.staffId || existingUser?.email
        }&&resetPassword=true`,
      );
    },
  });
  const submit = (values) => {
    existingUser({
      variables: {
        accountType: 'USER',
        identifier: values.email,
        institutionId,
      },
    });
  };

  return (
    <DFAAuthLayout
      imageSrc={logoSvg}
      title="Forgot Your Password?"
      description="Please provide the email address you used when you signed up for your Tech4dev Digital For All account."
    >
      <form className={classes.form} onSubmit={handleSubmit(submit)}>
        <TextField
          label="Email"
          name="email"
          type="email"
          inputRef={register({
            required: true,
            pattern: { value: EMAIL_REGEX, message: 'Please enter a valid format' },
          })}
          variant="outlined"
          fullWidth
        />
        <LoadingButton
          type="submit"
          fullWidth
          isLoading={loading}
          color="primary"
          className={classes.button}
        >
          Proceed
        </LoadingButton>
      </form>
      <Typography className={classes.link}>
        <Link to={PublicPaths.DFA_LOGIN} style={{ color: green[500], textDecoration: 'none' }}>
          Back to Login
        </Link>
      </Typography>
    </DFAAuthLayout>
  );
};

const useStyles = makeStyles(() => ({
  form: {
    '& > *': {
      marginBottom: spaces.medium,
    },
  },

  rememberPassword: {
    fontSize: fontSizes.medium,
    fontFamily: fontFamily.nunito,
    fontWeight: fontWeight.regular,
    color: colors.textLight,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  footer: {
    fontSize: fontSizes.medium,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerText: {
    fontFamily: fontFamily.nunito,
    fontWeight: fontWeight.regular,
    fontSize: fontSizes.medium,
    color: colors.textLight,
  },
  link: {
    fontSize: fontSizes.large,
    fontFamily: fontFamily.nunito,
    fontWeight: fontWeight.regular,
    color: colors.successBg,
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  regularLink: {
    fontSize: fontSizes.medium,
    fontFamily: fontFamily.nunito,
    fontWeight: fontWeight.regular,
    color: colors.successBg,
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  strongLink: {
    fontSize: fontSizes.large,
    fontFamily: fontFamily.primary,
    fontWeight: 900,
    color: colors.successBg,
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  existingUsersLink: {
    textDecoration: 'none',
    color: 'inherit',
  },

  button: {
    color: '#fff',
    backgroundColor: colors.successBg,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));

export default React.memo(DFAForgotPassword);
