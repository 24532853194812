import { Typography } from '@material-ui/core';
export const employmentStatusOptions = [
  {
    value: 'STUDENT_AWAITING_ADMISSION',
    label: 'A student waiting for admission into the university/tertiary institution.',
  },
  {
    value: 'STUDENT_EMPLOYED',
    label: 'A student (in a tertiary institution) and employed.',
  },
  {
    value: 'STUDENT_SELF_EMPLOYED',
    label: 'A student (in a tertiary institution) and self-employed.',
  },
  {
    value: 'STUDENT_EMPLOYER',
    label: 'A student (in a tertiary institution) and also an employer.',
  },
  {
    value: 'UNEMPLOYED',
    label: 'Not working anywhere at the moment.',
  },
  {
    value: 'EMPLOYEE',
    label: 'An employee.',
  },
  {
    value: 'SELF_EMPLOYED',
    label: 'Self-employed (one-man business)',
  },
  {
    value: 'EMPLOYER',
    label: 'An employer (provides jobs to one or more people).',
  },
  {
    value: 'RETIRED',
    label: 'Retired',
  },
];

export const educationLevelOptions = [
  { value: 'NONE', label: 'None' },
  { value: 'PRIMARY_EDUCATION', label: 'Primary education' },
  { value: 'SECONDARY_EDUCATION', label: 'Secondary education' },
  { value: 'DIPLOMA', label: 'Short-cycle tertiary education (Diploma)' },
  { value: 'BACHELOR_EQUIVALENT', label: 'Bachelor’s or equivalent level' },
  { value: 'POST_GRADUATE_DIPLOMA', label: 'Post-Graduate Diploma level' },
  { value: 'MASTERS', label: 'Master’s or equivalent level' },
  { value: 'DOCTORATE', label: 'Doctoral or equivalent level' },
];

export const employmentOptions = [
  { value: 'CIVIL_SERVANT', label: 'Civil Servant' },
  { value: 'BUSINESS_OWNER', label: 'Business Owner' },
  { value: 'PRIVATE_ORGANIZATION_EMPLOYEE', label: 'Employee in a private Organization' },
  { value: 'NON_WORKING_STUDENT', label: 'Non-working Student' },
  { value: 'UNEMPLOYED_RETIRED', label: 'Unemployed/Retired' },
];

export const booleanOptions = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' },
];

export const accessTools = [
  { value: 'DESKTOP', label: 'Desktop' },
  { value: 'LAPTOP', label: 'Laptop' },
  { value: 'TABLET', label: 'Tablet' },
  { value: 'SMARTPHONE', label: 'Smartphone' },
];

export const levelOfProgramQuality = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const learningStyle = [
  { value: 'SELF_PACED_LEARNING', label: 'Self-paced learning. ' },
  { value: 'ONLINE_INSTRUCTOR_LED_LEARNING', label: 'Online Instructor-led learning.  ' },
  { value: 'PHYSICAL_INSTRUCTOR_LEARNING', label: 'Physical Instructor-led learning.  ' },
  { value: 'HYBRID_LEARNING', label: 'Hybrid learning (online and in-person).  ' },
];

export const challenges = [
  {
    value: 'COMBINING_SCHOOLWORK_WITH_THE_TRAINING_PROGRAM',
    label: 'Combining schoolwork with the training program',
  },
  {
    value: 'BALANCING_WORK_RESPONSIBILITIES_WITH_THE_TRAINING_SCHEDULE',
    label: 'Balancing work responsibilities with the training schedule ',
  },
  {
    value: 'HELPING_THE_FAMILY_TO_MAKE_A_LIVING',
    label: 'Helping the family to make a living. ',
  },
  {
    value: 'I_JOINED_BECAUSE_IT_S_POPULAR_NOT_BECAUSE_I_M_REALLY_INTERESTED',
    label: "I joined because it's popular, not because I'm really interested.",
  },
];

export const programSuccessMeasure = [
  {
    value: 'SIGNIFICANT_IMPROVEMENT_IN_MY_KNOWLEDGE',
    label: 'Significant improvement in my knowledge',
  },
  {
    value: 'I_HAVE_MORE_SELF_CONFIDENCE_IN_MY_DIGITAL_SKILLS_THAN_BEFORE_I_STARTED_THE_DFA',
    label: 'I have more self-confidence in my digital skills than before I started the DFA',
  },
  {
    value: 'I_HAVE_AN_IMPROVED_FOUNDATION_NEEDED_FOR_A_TECH_CAREER',
    label: 'I have an improved foundation needed for a tech career. ',
  },
  {
    value: 'FEELING_CONFIDENT_THAT_I_LEARNT_A_MEANINGFUL_SKILL',
    label: 'Feeling confident that I learnt a meaningful skill. ',
  },
  {
    value: 'ABILITY_TO_APPLY_SKILLS_IN_REAL_WORLD_SITUATIONS',
    label: 'Ability to apply skills in real-world situations. ',
  },
];

export const frequencyOptions = [
  { value: 'NO_ACCESS', label: 'No Access' },
  { value: 'ONCE_A_WEEK', label: 'Once a Week' },
  { value: 'EVERYDAY', label: 'Everyday' },
];

export const maritalStatusOptions = [
  { value: 'SINGLE_NEVER_MARRIED', label: 'Single/Never Married' },
  { value: 'MARRIED', label: 'Married' },
  { value: 'DIVORCED', label: 'Divorced' },
  { value: 'SEPARATED', label: 'Separated' },
  { value: 'WIDOWED', label: 'Widowed' },
];

export const incomeOptions = [
  { value: 'NO_INCOME', label: 'No income' },
  { value: 'LESS_THAN_50000', label: 'Less than 50,000' },
  { value: 'A_50000_99999', label: '50,000-99,999' },
  { value: 'A_100000_299999', label: '100,000 - 299,999' },
  { value: 'A_300000_499999', label: '300,000-499,999' },
  { value: 'A_500000_1000000', label: '500,000 - 1,000,000' },
  { value: 'ABOVE_1000000', label: 'Above 1,000,000' },
];

export const residentialAreaOptions = [
  {
    value: 'URBAN',
    label:
      'Urban (an area with moderate to high basic amenities such as good road network, good electricity supply, hospitals, schools etc.)',
  },
  {
    value: 'SEMI_URBAN',
    label:
      'Semi-Urban (an area with some level or presence of basic amenities - electricity, road network, hospitals, schools, etc.)',
  },
  {
    value: 'RURAL',
    label:
      'Rural (an area with little or no presence of basic amenities - electricity, road network, hospitals, schools, etc.)',
  },
];

export const extentOfDigitalSkillsOptions = [
  { value: 'NO_TECH', label: 'No Tech' },
  { value: 'A_LITTLE_TECH', label: 'A little Tech' },
  { value: 'AVERAGE_TECH', label: 'Average Tech' },
  { value: 'LOT_OF_TECH', label: 'A lot of Tech' },
  { value: 'EXTREMELY_TECH', label: 'Extremely Tech' },
];

export const computerAbilityOptions = [
  {
    value: 'NO_PRIOR_KNOWLEDGE',
    label: 'I don’t have any prior knowledge on how to use a computer',
  },
  {
    value: 'BASIC_KNOWLEDGE',
    label: 'I have basic knowledge of MS word and other Office tools',
  },
  {
    value: 'ADVANCE_KNOWLEDGE',
    label: 'I can use spread sheets, databases, and even do some data modelling',
  },
  {
    value: 'MORE_ADVANCE_KNOWLEDGE',
    label:
      'I can do some amazing things with the computer (e.g. code, resolve network issues, configure systems, analyse complex data, etc).',
  },
];

export const digitalSkillsTrainingJourneyOptions = [
  {
    value: 'NEVER_PARTICIPATED',
    label: (
      <Typography>
        I have <strong>never</strong> participated in a <strong>digital skills</strong> training
        before.
      </Typography>
    ),
  },
  {
    value: 'PHYSICAL_TRAINING',
    label: (
      <Typography>
        The digital skills training(s) I have participated in was/were <strong>physical</strong>{' '}
        training.
      </Typography>
    ),
  },
  {
    value: 'ONLINE_TRAINING',
    label: (
      <Typography>
        The digital training(s) that I have participated in was/were <strong>online</strong>{' '}
        training.
      </Typography>
    ),
  },
  {
    value: 'BOTH_PHYSICAL_ONLINE',
    label: (
      <Typography>
        I have participated in <strong>both</strong> physical and online digital skills training(s)
        before.
      </Typography>
    ),
  },
];

export const learningTrackOptions = [
  {
    value: 'DIGITAL_MARKETING_AND_SOCIAL_MEDIA_MARKETING',
    label: 'Digital Marketing and Social Media Marketing',
  },
  {
    value: 'BASIC_DIGITAL_LITERACY',
    label: 'Basic Digital Literacy (BDL)',
  },
  {
    value: 'CYBER_SECURITY',
    label: 'Cyber Security',
  },
  {
    value: 'SOFTWARE_DEVELOPMENT',
    label: 'Software Development',
  },
  {
    value: 'DATA_SCIENCE_AI',
    label: 'Data Science and AI',
  },
  {
    value: 'PRODUCT_DESIGN',
    label: 'Product Design',
  },
  {
    value: 'PRODUCT_MANAGEMENT',
    label: 'Product Management',
  },
];

export const digitalSkillsOptions = [
  { value: 'DIGITAL_MARKETING', label: 'Digital Marketing' },
  { value: 'SOFTWARE_DEVELOPMENT', label: 'Software Development' },
  { value: 'GRAPHICS_DESIGN', label: 'Graphics Design' },
  { value: 'PRODUCT_DESIGN', label: 'Product Design' },
  { value: 'PRODUCT_MANAGEMENT', label: 'Product Management' },
  { value: 'DATA_SCIENCE', label: 'Data Science' },
  { value: 'CYBER_SECURITY', label: 'Cyber Security' },
  { value: 'A_3D_ANIMATION', label: '3D Animation' },
  { value: 'BLOCK_CHAIN_TECHNOLOGY', label: 'Blockchain Technology' },
  { value: 'OTHER', label: 'Other' },
];

export const platformOptions = [
  { value: 'FACEBOOK', label: 'Facebook' },
  { value: 'INSTAGRAM', label: 'Instagram' },
  { value: 'TWITTER', label: 'Twitter' },
  { value: 'WHATSAPP', label: 'WhatsApp' },
  { value: 'LINKEDIN', label: 'LinkedIn' },
  { value: 'WEBSITE', label: 'Website' },
  { value: 'THREADS', label: 'Threads' },
  { value: 'OTHER', label: 'Other Internet Platform' },
  { value: 'WORD_OF_MOUTH', label: 'Word of Mouth' },
];

export const categoryOptions = [
  { value: 'YOUTH', label: 'Youth' },
  { value: 'CIVIL_SERVANT', label: 'Civil Servant' },
  { value: 'K12_TEACHER', label: 'K12 Teacher' },
];

export const schoolCategoryOptions = [
  { value: 'PRIMARY', label: 'Primary' },
  { value: 'SECONDARY', label: 'Secondary' },
];

export const schoolTypeOptions = [
  { value: 'PRIVATE', label: 'Private' },
  { value: 'PUBLIC', label: 'Public' },
];

export const genderIntentionalOptions = [
  { value: 'ALL_FEMALE_SCHOOL', label: 'All-Female School' },
  { value: 'ALL_MALE_SCHOOL', label: 'All-Male School' },
  { value: 'MIXED', label: 'Mixed (Boys and Girls) School' },
  { value: 'NOT_APPLICABLE', label: 'Not-Applicable (A Primary School)' },
];

export const subjectCategoryOptions = [
  { value: 'SCIENCE', label: 'Science' },
  { value: 'TECHNOLOGY', label: 'Technology' },
  { value: 'MATHEMATICS', label: 'Mathematics' },
  { value: 'ENGINEERING', label: 'Engineering' },
  { value: 'ARTS', label: 'Arts' },
  { value: 'SOCIAL_SCIENCES', label: 'Social Sciences' },
  { value: 'OTHERS', label: 'Others' },
];
