import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React from 'react';
import { getFormError } from 'utils/formError';
import { DFA_PHONE_REGEX, EMAIL_REGEX, PHONE_REGEX } from 'utils/constants.js';
import useStateAndLGA from 'hooks/useStateAndLGA';
import { maritalStatusOptions, residentialAreaOptions } from './variables';
import { colors, fontWeight } from '../../../Css';

const StepOne = (props) => {
  const { control, register, errors, getValues, handleChange, trigger, formData, setValue } = props;

  const { states } = useStateAndLGA(getValues('stateOfOrigin)'));
  const state = useStateAndLGA(getValues('currentState)'));

  const currentState = state?.states;
  return (
    <Grid container>
      <Typography style={{ fontSize: '16px', fontWeight: fontWeight.bold, color: '#083A55' }}>
        The information you provide in this section will help us to know you.
      </Typography>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              style={{ color: '#083A55', marginBottom: '12px', fontWeight: fontWeight.bold }}
            >
              First Name <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              name="firstname"
              control={control}
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  {...register('firstname', { required: true })}
                  placeholder="First name"
                  variant="outlined"
                  required
                  inputRef={register({ required: true })}
                  onKeyUp={() => trigger('firstname')}
                  fullWidth
                  error={getFormError('firstname', errors).hasError}
                  helperText={getFormError('firstname', errors).message}
                  size="medium"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              style={{ color: '#083A55', marginBottom: '12px', fontWeight: fontWeight.bold }}
            >
              Middle Name
            </Typography>
            <Controller
              name="middlename"
              control={control}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  placeholder="Middle name"
                  variant="outlined"
                  fullWidth
                  size="medium"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}>
              Last Name <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              name="lastname"
              control={control}
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  placeholder="Last name"
                  variant="outlined"
                  onKeyUp={() => trigger('lastname')}
                  required
                  fullWidth
                  inputRef={register({ required: true })}
                  error={getFormError('lastname', errors).hasError}
                  helperText={getFormError('lastname', errors).message}
                  size="medium"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}>
              Phone Number (i.e +234xxxxxxxx) <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              name="phone"
              control={control}
              rules={{
                required: true,
                pattern: { value: DFA_PHONE_REGEX, message: 'Please enter a valid format' },
              }}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  placeholder="Phone number"
                  variant="outlined"
                  required
                  onKeyUp={() => trigger('phone')}
                  fullWidth
                  inputRef={register({ required: true })}
                  error={getFormError('phone', errors).hasError}
                  helperText={getFormError('phone', errors).message}
                  size="medium"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '40px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl component="fieldset">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel
                  component="legend"
                  style={{ marginRight: '10px', color: '#083A55', fontWeight: fontWeight.bold }}
                >
                  Gender:
                  <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <Controller
                  name="gender"
                  rules={{ required: true }}
                  control={control}
                  render={({ field, value }) => {
                    return (
                      <RadioGroup
                        {...field}
                        aria-label="gender"
                        name="gender"
                        onChange={handleChange}
                        style={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel
                          style={{ color: '#083A55' }}
                          value="MALE"
                          checked={value === 'MALE'}
                          control={<GreenRadio />}
                          label="Male"
                        />
                        <FormControlLabel
                          style={{ color: '#083A55' }}
                          value="FEMALE"
                          checked={value === 'FEMALE'}
                          control={<GreenRadio />}
                          label="Female"
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </div>
            </FormControl>

            {getFormError('gender', errors).hasError && (
              <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
                {getFormError('gender', errors).message}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '40px' }}>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography
                style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
              >
                Email <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ ref, ...rest }) => (
                  <TextField
                    {...rest}
                    variant="outlined"
                    placeholder="Email"
                    required
                    fullWidth
                    inputRef={register({
                      required: true,
                      pattern: { value: EMAIL_REGEX, message: 'Please enter a valid format' },
                    })}
                    error={getFormError('email', errors).hasError}
                    helperText={getFormError('email', errors).message}
                    size="medium"
                    onKeyUp={() => trigger('email')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography
                style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
              >
                Age (i.e 30) <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Controller
                name="age"
                control={control}
                rules={{
                  required: true,
                  min: { value: 0, message: 'Value cannot be less than 0' },
                }}
                render={({ ref, ...rest }) => (
                  <TextField
                    {...rest}
                    type="number"
                    placeholder="Age"
                    variant="outlined"
                    required
                    fullWidth
                    inputRef={register({
                      required: true,
                    })}
                    error={getFormError('age', errors).hasError}
                    helperText={getFormError('age', errors).message}
                    size="medium"
                    onKeyUp={() => trigger('age')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
              >
                Marital Status <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Controller
                name="maritalStatus"
                control={control}
                rules={{ required: true }}
                render={({ field, value }) => (
                  <RadioGroup
                    {...field}
                    aria-label="marital status"
                    onChange={handleChange}
                    name="maritalStatus"
                  >
                    {maritalStatusOptions.map((option) => (
                      <FormControlLabel
                        style={{ color: '#083A55' }}
                        checked={value === option.value}
                        key={option.value}
                        value={option.value}
                        control={<GreenRadio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              {getFormError('maritalStatus', errors).hasError && (
                <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
                  {getFormError('maritalStatus', errors).message}
                </p>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Typography
                  style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
                >
                  State of Origin <span style={{ color: 'red' }}>*</span>
                </Typography>

                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="stateOfOrigin"
                  render={(field) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      className="input"
                      variant="outlined"
                      size="medium"
                      error={getFormError('stateOfOrigin', errors).hasError}
                      helperText={getFormError('stateOfOrigin', errors).message}
                      onChange={handleChange}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option defaultChecked>Select state</option>
                      {states.map((state, i) => (
                        <option key={i} value={state}>
                          {state}
                        </option>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Typography
                  style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
                >
                  Current State of Residence <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="currentState"
                  render={(field) => (
                    <TextField
                      {...field}
                      select
                      fullWidth
                      className="input"
                      variant="outlined"
                      size="medium"
                      error={getFormError('currentState', errors).hasError}
                      helperText={getFormError('currentState', errors).message}
                      SelectProps={{
                        native: true,
                      }}
                      onChange={handleChange}
                    >
                      <option defaultChecked>Select state</option>
                      {currentState.map((state, i) => (
                        <option key={i} value={state}>
                          {state}
                        </option>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
              >
                Types of residential area <span style={{ color: 'red' }}>*</span>
              </FormLabel>
              <Controller
                name="residentialArea"
                control={control}
                rules={{ required: true }}
                render={({ field, value }) => (
                  <RadioGroup
                    aria-label="residentialArea"
                    name="residentialArea"
                    onChange={handleChange}
                  >
                    {residentialAreaOptions.map((option) => (
                      <FormControlLabel
                        style={{
                          color: '#083A55',
                          display: 'flex',
                          alignItems: 'flex-start',
                          width: '100%',
                          marginBottom: '8px',
                        }}
                        key={option.value}
                        checked={value === option.value}
                        value={option.value}
                        control={<GreenRadio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              {getFormError('residentialArea', errors).hasError && (
                <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
                  {getFormError('residentialArea', errors).message}
                </p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepOne;
const GreenRadio = withStyles({
  root: {
    color: colors.grey,
    '&$checked': {
      color: '#287D3C',
    },
    marginRight: 10,
    padding: 7,
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
