import {
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  categoryOptions,
  genderIntentionalOptions,
  schoolCategoryOptions,
  schoolTypeOptions,
  subjectCategoryOptions,
} from './variables';
import { getFormError } from 'utils/formError';
import { useMemo } from 'react';
import { colors, fontWeight } from '../../../Css';

const StepFive = (props) => {
  const { control, watch, register, errors, handleChange, trigger } = props;

  // const isK12Teacher = watch('category') === 'K12_TEACHER';
  const isK12Teacher = useMemo(() => watch('category') === 'K12_TEACHER', [watch]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: '40px' }}>
        <FormLabel
          component="legend"
          style={{ color: '#083A55', margin: '12px 0', fontWeight: fontWeight.bold }}
        >
          Category
        </FormLabel>
        <Typography style={{ fontSize: '14px', fontStyle: 'italic' }}>
          Select the category you want to participate in.
          <span style={{ color: 'red', marginRight: '4px' }}>*</span>
          <Link style={{ color: '#3CAE5C', fontWeight: 700 }}>Learn more</Link>
        </Typography>
        <Controller
          name="category"
          control={control}
          rules={{ required: true }}
          render={({ field, value }) => (
            <RadioGroup {...field} aria-label="category" name="category" onChange={handleChange}>
              {categoryOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<GreenRadio />}
                  label={option.label}
                  style={{
                    color: '#083A55',
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                    marginBottom: '8px',
                  }}
                  checked={value === option.value}
                />
              ))}
            </RadioGroup>
          )}
        />
        {getFormError('category', errors).hasError && (
          <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
            {getFormError('category', errors).message}
          </p>
        )}
      </Grid>
      {isK12Teacher && (
        <>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <Typography>
              What is the name of your school?<span style={{ color: 'red' }}>*</span>
            </Typography>

            <Controller
              rules={{ required: true }}
              name="schoolName"
              control={control}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  placeholder="Enter your school name"
                  variant="outlined"
                  required
                  onKeyUp={() => trigger('schoolName')}
                  fullWidth
                  inputRef={register({ required: true })}
                  error={getFormError('schoolName', errors).hasError}
                  helperText={getFormError('schoolName', errors).message}
                  size="medium"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <FormLabel component="legend">
              Kindly select the category of your school<span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Controller
              name="schoolCategory"
              rules={{ required: true }}
              control={control}
              render={({ field, value }) => (
                <RadioGroup
                  {...field}
                  aria-label="schoolCategory"
                  name="schoolCategory"
                  onChange={handleChange}
                >
                  {schoolCategoryOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<GreenRadio />}
                      checked={value === option.value}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            />
            {getFormError('schoolCategory', errors).hasError && (
              <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
                {getFormError('schoolCategory', errors).message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <FormLabel component="legend">
              Indicate whether your school is a private or public school
              <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Controller
              name="schoolType"
              rules={{ required: true }}
              control={control}
              render={({ field, value }) => (
                <RadioGroup
                  {...field}
                  aria-label="schoolType"
                  name="schoolType"
                  onChange={handleChange}
                >
                  {schoolTypeOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<GreenRadio />}
                      checked={value === option.value}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            />{' '}
            {getFormError('schoolType', errors).hasError && (
              <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
                {getFormError('schoolType', errors).message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <FormLabel component="legend">
              Indicate if your school is a gender-intentional school
              <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Controller
              name="genderIntentional"
              rules={{ required: true }}
              control={control}
              render={({ field, value }) => (
                <RadioGroup
                  {...field}
                  aria-label="genderIntentional"
                  name="genderIntentional"
                  onChange={handleChange}
                >
                  {genderIntentionalOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<GreenRadio />}
                      checked={value === option.value}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            />
            {getFormError('genderIntentional', errors).hasError && (
              <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
                {getFormError('genderIntentional', errors).message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <Typography>
              How many subjects do you teach in your current school?
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              rules={{ required: true }}
              name="numberOfSubjects"
              control={control}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  type="number"
                  placeholder="Enter the number of subjects you teach"
                  variant="outlined"
                  onKeyUp={() => trigger('numberOfSubjects')}
                  fullWidth
                  inputRef={register({ required: true })}
                  error={getFormError('numberOfSubjects', errors).hasError}
                  helperText={getFormError('numberOfSubjects', errors).message}
                  size="medium"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <FormLabel component="legend">
              Which of the following categories does the main subject taught by you fall into?
              <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Controller
              rules={{ required: true }}
              name="subjectCategory"
              control={control}
              render={({ field, value }) => (
                <RadioGroup
                  {...field}
                  aria-label="subjectCategory"
                  name="subjectCategory"
                  onChange={handleChange}
                >
                  {subjectCategoryOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<GreenRadio />}
                      checked={value === option.value}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            />
            {getFormError('subjectCategory', errors).hasError && (
              <p style={{ color: '#f44336', fontSize: '0.75rem' }}>
                {getFormError('subjectCategory', errors).message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <Typography>
              Indicate the name of the main subject taught by you
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              rules={{ required: true }}
              name="subjectName"
              control={control}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  placeholder="Enter the name of the subject(s) you teach"
                  variant="outlined"
                  onKeyUp={() => trigger('subjectName')}
                  required
                  fullWidth
                  inputRef={register({ required: true })}
                  error={getFormError('subjectName', errors).hasError}
                  helperText={getFormError('subjectName', errors).message}
                  size="medium"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '40px' }}>
            <Typography>
              What certification have you received to enhance your teaching?
              <span style={{ color: 'red' }}>*</span> Please state
            </Typography>
            <Controller
              rules={{ required: true }}
              name="certification"
              control={control}
              render={({ ref, ...rest }) => (
                <TextField
                  {...rest}
                  placeholder="Enter the certification you've received"
                  variant="outlined"
                  onKeyUp={() => trigger('certification')}
                  required
                  fullWidth
                  inputRef={register({ required: true })}
                  error={getFormError('certification', errors).hasError}
                  helperText={getFormError('certification', errors).message}
                  size="medium"
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default StepFive;
const GreenRadio = withStyles({
  root: {
    color: colors.grey,
    '&$checked': {
      color: '#287D3C',
    },
    marginRight: 10,
    padding: 3,
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
