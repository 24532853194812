import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import DFAAccountSetup from 'pages/Authentication/DFAAuthentication/AccountSetup';
import NotFoundPage from 'pages/NotFoundPage';
import Redirects from 'pages/Redirects';
import DFALoadingAnimation from 'reusables/DFALoadingAnimation';
import DFASplashscreen from 'reusables/DFASplashscreen';
import history from './history';
import PrivateRoute from './PrivateRoute';
import Registration from 'pages/Authentication/DFARegistration';
import DFALogin from 'pages/Authentication/DFAAuthentication/DFALogin';
import DFAResetPassword from 'pages/Authentication/DFAAuthentication/DFAResetPassword';
import DFAForgotPassword from 'pages/Authentication/DFAAuthentication/DFAForgotPassword';
import DFAExistingUserOTPVerification from 'pages/Authentication/DFAAuthentication/DFAExistingUserOTPVerification';

export const PublicPaths = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  INSTRUCTOR: '/instructor-registration',
  LEARNERS_REGISTRATION: '/learners-registration',
  LEARNERS_REGISTRATION_STAGES: '/learners-registration-stages',
  ACCOUNT_SETUP: '/account-setup',
  EXISTING_USER_OTP_VERIFICATION: '/existing-user-otp-verification',
  EXISTING_USER_PASSWORD_CREATION: '/existing-user-password-creation',
  EXISTING_USER_VALIDATION: '/existing-user-validation',
  SCHOOL_LIST: '/school-list',
  EXISTING_USER_DATA_PAGE: '/verify-data',
  REGISTER: '/register',
  DFA_LOGIN: '/login',
  DFA_RESET_PASSWORD: '/reset-password',
  DFA_FORGOT_PASSWORD: '/forgot-password',
  DFA_EXISTING_USER_OTP_VERIFICATION: '/existing-user-otp-verification',
};

export const PrivatePaths = {
  INSTITUTIONS: '/institutions',
  COURSES: '/courses',
  LEARNERS: '/learners',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  MY_LEARNING: '/my-learning',
  LIBRARY: '/library',
  LIVE_SESSION: '/live-session',
  PROFILE: '/profile',
  HELP_PAGE: '/get-help',
  ANNOUNCEMENT: '/announcement',
  EXECUTIVE: '/executive',
  LOW_GRADE: '/low-grade',
  NOTIFICATION: '/notifications',
  CREATE_TASK: '/create_task',
  ASSESSMENTS: '/assessments',
  ACTIVITY_LOG: '/activity-log',
  DFA_ACTIVITY_LOG: '/activity-log',
  DFA_ASSESSMENTS: '/assessments',
  DFA_DASHBOARD: '/dashboard',
  DFA_GENERAL_ASSESSMENT: '/assessment',
  DFA_START_ASSESSMENT: '/start-assessment',
  DFA_INTERMEDIATE_ASSESSMENT: '/intermediate-assessment',
  DFA_INSTITUTIONS: '/institutions',
  DFA_USERS: '/users',
  DFA_COURSES: '/courses',
  DFA_ANNOUNCEMENT: '/announcement',
  DFA_LIVE_SESSION: '/live-session',
};


const publicRoutes = [
  /* Add paths for unauthorized DFA users */
  { path: PublicPaths.DFA_LOGIN, exact: true, component: DFALogin },
  { path: PublicPaths.DFA_FORGOT_PASSWORD, exact: true, component: DFAForgotPassword },
  {
    path: PublicPaths.DFA_EXISTING_USER_OTP_VERIFICATION,
    exact: true,
    component: DFAExistingUserOTPVerification,
  },
  { path: PublicPaths.DFA_RESET_PASSWORD, exact: true, component: DFAResetPassword },
  {
    path: PublicPaths.REGISTER,
    exact: true,
    component: Registration,
  },
  {
    path: PublicPaths.ACCOUNT_SETUP,
    exact: true,
    component: DFAAccountSetup,
  },
];

const privateRoutes = [
  /* Add paths for authorized DFA users */
  {
    path: PrivatePaths.DFA_ACTIVITY_LOG,
    exact: false,
    component: lazy(() => import('./DFA/DFAActivityLogRoutes')),
  },

  {
    path: PrivatePaths.DFA_ASSESSMENTS,
    exact: false,
    component: lazy(() => import('./DFA/DFAAssessmentRoutes')),
  },
  {
    path: PrivatePaths.DFA_GENERAL_ASSESSMENT,
    exact: false,
    component: lazy(() => import('./DFA/GeneralAssessmentRoutes')),
  },
  {
    path: PrivatePaths.DFA_INTERMEDIATE_ASSESSMENT,
    exact: false,
    component: lazy(() => import('./DFA/GeneralAssessmentRoutes')),
  },
  // {
  //   path: PrivatePaths.DFA_START_ASSESSMENT,
  //   exact: false,
  //   component: lazy(() => import('./DFA/GeneralAssessmentRoutes')),
  // },

  {
    path: PrivatePaths.DFA_DASHBOARD,
    exact: false,
    component: lazy(() => import('./DFA/DFADashboardRoutes')),
  },
  // {
  //   path: PrivatePaths.DFA_ASSESSMENT_DASHBOARD,
  //   exact: false,
  //   component: lazy(() => import('./DFA/DFADashboardRoutes')),
  // },
  {
    path: PrivatePaths.DFA_INSTITUTIONS,
    exact: false,
    component: lazy(() => import('./DFA/DFAInstitutionRoutes')),
  },
  {
    path: PrivatePaths.DFA_USERS,
    exact: false,
    component: lazy(() => import('./DFA/UsersRoutes')),
  },
  {
    path: PrivatePaths.DFA_COURSES,
    exact: false,
    component: lazy(() => import('./DFA/DFACoursesRoutes')),
  },
  {
    path: PrivatePaths.DFA_ANNOUNCEMENT,
    exact: false,
    component: lazy(() => import('./DFA/DFAAnnouncementRoutes')),
  },
  {
    path: PrivatePaths.DFA_LIVE_SESSION,
    exact: false,
    component: lazy(() => import('./DFA/DFALiveSessionRoutes')),
  },
];

const Routes = () => {

  return (
    <DFASplashscreen>
      <Suspense fallback={<DFALoadingAnimation />}>
        <Router history={history}>
            <Route path="/" exact component={Redirects} />
          <Switch>
            {publicRoutes?.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            {privateRoutes?.map((route, index) => (
              <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </Suspense>
    </DFASplashscreen>
  );
};

export default Routes;
